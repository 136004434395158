import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   backdrop: {
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      zIndex: 999999,
      color: theme.palette.primary.main,
   },
}));

const LoadingOverlay = ({ open }) => {
   const classes = useStyles();
   return (
      <Backdrop className={classes.backdrop} open={open}>
         <CircularProgress color="inherit" />
      </Backdrop>
   );
};

export default React.memo(LoadingOverlay);