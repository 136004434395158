export type SectionItem = {
  label: string;
  checkedByDefault?: boolean;
  hasSpecificDisplay?: boolean;
  defaultSort?: string;
  isMandatoryPipeView?: boolean,
  isMandatoryTestView?: boolean,
  isForPipeView?: boolean,
  isForTestView?: boolean,
  chemicalAnalysisGroup?: boolean;
  tensileTestGroup?: boolean;
  impactTestGroup?: boolean;
  hardnessTestGroup?: boolean;
  keys?: SectionItem[];
};

export type Section = {
  label: string;
  isForPipeView?: boolean;
  isForTestView?: boolean;
  keys: SectionItem[];
};
export default class ColumnsOrdering {
  public static sections: Section[] = [
    {
      "label": "pipe_identification",
      "isForPipeView": true,
      "isForTestView": false,
      "keys": [
        {
          "label": "tallyNumber",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "defaultSort": "asc",
          "isMandatoryPipeView": true,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "valid",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "ippn",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "manufacturerPipeId",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "pipeline.code",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        }
      ]
    },
    {
      "label": "order_information",
      "isForPipeView": true,
      "isForTestView": false,
      "keys": [
        {
          "label": "carbonSteelItemPlpp.carbonSteelOrder.vallourecOrderReference",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "carbonSteelItemPlpp.carbonSteelOrder.customerOrderReference",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "carbonSteelItemPlpp.carbonSteelOrder.millOrderReference",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "carbonSteelItemPlpp.vallourecItemReference",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "carbonSteelItemPlpp.customerItemReference",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "carbonSteelItemPlpp.millItemReference",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "carbonSteelItemPlpp.carbonSteelOrder.customerProjectName",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "carbonSteelItemPlpp.carbonSteelOrder.carbonSteelManufacturer.name",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "carbonSteelItemPlpp.quantity",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "carbonSteelItemPlpp.quantityUnit",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "carbonSteelItemPlpp.itemDescription",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        }
      ]
    },
    {
      "label": "pipe_steel_specification",
      "isForPipeView": true,
      "isForTestView": false,
      "keys": [
        {
          "label": "carbonSteelItemPlpp.grade",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "carbonSteelItemPlpp.gradeSuffix",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "carbonSteelItemPlpp.specification",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        }
      ]
    },
    {
      "label": "pipe_dimension_specification",
      "isForPipeView": true,
      "isForTestView": false,
      "keys": [
        {
          "label": "carbonSteelItemPlpp.outerDiameter",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.outerDiameter",
              "checkedByDefault": true,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.outerDiameterToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.outerDiameterToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.outerDiameterToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "carbonSteelItemPlpp.length",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.length",
              "checkedByDefault": true,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.lengthToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.lengthToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.lengthToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "carbonSteelItemPlpp.wallThickness",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.wallThickness",
              "checkedByDefault": true,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.wallThicknessToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.wallThicknessToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.wallThicknessToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "carbonSteelItemPlpp.internalDiameter",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.internalDiameter",
              "checkedByDefault": true,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.internalDiameterToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.internalDiameterToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.internalDiameterToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "carbonSteelItemPlpp.eccentricity",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.eccentricity",
              "checkedByDefault": true,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.eccentricityUnit",
              "checkedByDefault": true,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.eccentricityToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.eccentricityToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.eccentricityToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "carbonSteelItemPlpp.straightness",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.straightness",
              "checkedByDefault": true,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.straightnessToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.straightnessToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.straightnessToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "carbonSteelItemPlpp.internalOutOfRoundness",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.internalOutOfRoundness",
              "checkedByDefault": true,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.internalOutOfRoundnessUnit",
              "checkedByDefault": true,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.internalOutOfRoundnessToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.internalOutOfRoundnessToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.internalOutOfRoundnessToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "carbonSteelItemPlpp.outerOutOfRoundness",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.outerOutOfRoundness",
              "checkedByDefault": true,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.outerOutOfRoundnessUnit",
              "checkedByDefault": true,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.outerOutOfRoundnessToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.outerOutOfRoundnessToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.outerOutOfRoundnessToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        }
      ]
    },
    {
      "label": "pipe_end_dimension_specification",
      "isForPipeView": true,
      "isForTestView": false,
      "keys": [
        {
          "label": "carbonSteelItemPlpp.outerDiameterPipeEnd",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.outerDiameterPipeEnd",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.outerDiameterPipeEndToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.outerDiameterPipeEndToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.outerDiameterPipeEndToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "carbonSteelItemPlpp.internalDiameterPipeEnd",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.internalDiameterPipeEnd",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.internalDiameterPipeEndToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.internalDiameterPipeEndToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.internalDiameterPipeEndToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "carbonSteelItemPlpp.wallThicknessPipeEnd",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.wallThicknessPipeEnd",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.wallThicknessPipeEndToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.wallThicknessPipeEndToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.wallThicknessPipeEndToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "carbonSteelItemPlpp.outerOutOfRoundnessPipeEnd",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.outerOutOfRoundnessPipeEnd",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.outerOutOfRoundnessPipeEndToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.outerOutOfRoundnessPipeEndToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.outerOutOfRoundnessPipeEndToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "carbonSteelItemPlpp.internalOutOfRoundnessPipeEnd",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.internalOutOfRoundnessPipeEnd",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.internalOutOfRoundnessPipeEndToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.internalOutOfRoundnessPipeEndToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.internalOutOfRoundnessPipeEndToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "carbonSteelItemPlpp.eccentricityPipeEnd",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.eccentricityPipeEnd",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.eccentricityPipeEndToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.eccentricityPipeEndToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.eccentricityPipeEndToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "carbonSteelItemPlpp.squarenessPipeEnd",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.squarenessPipeEnd",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.squarenessPipeEndToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.squarenessPipeEndToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.squarenessPipeEndToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "carbonSteelItemPlpp.straightnessPipeEnd",
          "keys": [
            {
              "label": "carbonSteelItemPlpp.straightnessPipeEnd",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.straightnessPipeEndToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.straightnessPipeEndToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "carbonSteelItemPlpp.straightnessPipeEndToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        }
      ]
    },
    {
      "label": "pipe_dimension_measurement",
      "isForPipeView": true,
      "isForTestView": false,
      "keys": [
        {
          "label": "actualLength",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "actualWeight",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "wallThickness",
          "keys": [
            {
              "label": "wallThicknessMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "wallThicknessMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "wallThicknessAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "outerDiameter",
          "keys": [
            {
              "label": "outerDiameterMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outerDiameterMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outerDiameterAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "internalDiameter",
          "keys": [
            {
              "label": "internalDiameterMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "internalDiameterMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "internalDiameterAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "outOfRoundnessOuter",
          "keys": [
            {
              "label": "outOfRoundnessOuterMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outOfRoundnessOuterMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outOfRoundnessOuterAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "outOfRoundnessInternal",
          "keys": [
            {
              "label": "outOfRoundnessInternalMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outOfRoundnessInternalMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outOfRoundnessInternalAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "eccentricity",
          "keys": [
            {
              "label": "eccentricityMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "eccentricityMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "eccentricityAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "reviewedActualInternalDiameter",
          "keys": [
            {
              "label": "reviewedActualInternalDiameterTolerance",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "reviewedActualInternalDiameterToleranceUnit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "reviewedActualInternalDiameterToleranceMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "reviewedActualInternalDiameterToleranceMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        }
      ]
    },
    {
      "label": "pipe_end_dimension_measurement",
      "isForPipeView": true,
      "isForTestView": false,
      "keys": [
        {
          "label": "internalDiameterNorth",
          "keys": [
            {
              "label": "internalDiameterNorthMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "internalDiameterNorthMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "internalDiameterNorthAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "internalDiameterSouth",
          "keys": [
            {
              "label": "internalDiameterSouthMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "internalDiameterSouthMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "internalDiameterSouthAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "outerDiameterNorth",
          "keys": [
            {
              "label": "outerDiameterNorthMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outerDiameterNorthMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outerDiameterNorthAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "outerDiameterSouth",
          "keys": [
            {
              "label": "outerDiameterSouthMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outerDiameterSouthMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outerDiameterSouthAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "wallThicknessNorth",
          "keys": [
            {
              "label": "wallThicknessNorthMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "wallThicknessNorthMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "wallThicknessNorthAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "wallThicknessSouth",
          "keys": [
            {
              "label": "wallThicknessSouthMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "wallThicknessSouthMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "wallThicknessSouthAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "outOfRoundnessInternalNorth",
          "keys": [
            {
              "label": "outOfRoundnessInternalNorthMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outOfRoundnessInternalNorthMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outOfRoundnessInternalNorthAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "outOfRoundnessInternalSouth",
          "keys": [
            {
              "label": "outOfRoundnessInternalSouthMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outOfRoundnessInternalSouthMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outOfRoundnessInternalSouthAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "outOfRoundnessOuterNorth",
          "keys": [
            {
              "label": "outOfRoundnessOuterNorthMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outOfRoundnessOuterNorthMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outOfRoundnessOuterNorthAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "outOfRoundnessOuterSouth",
          "keys": [
            {
              "label": "outOfRoundnessOuterSouthMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outOfRoundnessOuterSouthMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "outOfRoundnessOuterSouthAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "eccentricityNorth",
          "keys": [
            {
              "label": "eccentricityNorthMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "eccentricityNorthMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "eccentricityNorthAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        },
        {
          "label": "eccentricitySouth",
          "keys": [
            {
              "label": "eccentricitySouthMin",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "eccentricitySouthMax",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            },
            {
              "label": "eccentricitySouthAverage",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": true,
              "isForTestView": false
            }
          ]
        }
      ]
    },
    {
      "label": "heat_identification",
      "isForPipeView": true,
      "isForTestView": true,
      "keys": [
        {
          "label": "carbonSteelHeat.number",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": true,
          "isForPipeView": true,
          "isForTestView": true
        },
        {
          "label": "carbonSteelHeat.testType",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": true,
          "isForPipeView": false,
          "isForTestView": true
        },
        {
          "label": "carbonSteelHeat.sampleNumber",
          "checkedByDefault": true,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": true,
          "isForPipeView": false,
          "isForTestView": true
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.steelplant",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.countryofsteel(melt)origin",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.regionofsteel(melt)origin",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true
        },
      ]
    },
    {
      "label": "chemical_composition",
      "isForPipeView": false,
      "isForTestView": true,
      "keys": [
        {
          "label": "carbonSteelHeat.chemicalAnalysis.al",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.al",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.al_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.al_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.as",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.as",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.as_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.as_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.b",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.b",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.b_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.b_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.bi",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.bi",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.bi_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.bi_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.c",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.c",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.c_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.c_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.ca",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ca",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ca_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ca_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.cr",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cr",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cr_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cr_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.cu",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cu",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cu_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cu_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.fe",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.fe",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.fe_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.fe_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.h",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.h",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.h_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.h_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.mg",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.mg",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.mg_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.mg_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.mo",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.mo",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.mo_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.mo_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.n",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.n",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.n_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.n_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.nb",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.nb",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.nb_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.nb_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.ni",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ni",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ni_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ni_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.o",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.o",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.o_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.o_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.p",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.p",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.p_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.p_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.s",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.s",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.s_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.s_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.sb",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.sb",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.sb_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.sb_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.si",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.si",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.si_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.si_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.sn",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.sn",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.sn_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.sn_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.ta",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ta",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ta_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ta_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.ti",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ti",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ti_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ti_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.v",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.v",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.v_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.v_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.w",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.w",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.w_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.w_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.zr",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.zr",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.zr_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.zr_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.altot",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.altot",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.altot_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.altot_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.almet",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.almet",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.almet_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.almet_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.alsol",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.alsol",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.alsol_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.alsol_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.co",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.co",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.co_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.co_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.chemicalAnalysis.zn",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.zn",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.zn_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.zn_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        },
        {
          "label": "formulas",
          "keys": [
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1003",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1003_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1003_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2650",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2650_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2650_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1011",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1011_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1011_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1001",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1001_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1001_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0010",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0010_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0010_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0014",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0014_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0014_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2528",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2528_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2528_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0001",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0001_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0001_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0002",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0002_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0002_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.pb",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.pb_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.pb_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2557",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2557_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2557_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0003",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0003_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0003_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0013",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0013_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0013_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0015",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0015_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0015_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0019",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0019_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0019_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0027",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0027_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0027_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0028",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0028_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0028_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0031",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0031_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0031_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0053",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0053_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0053_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0076",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0076_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0076_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0086",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0086_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.0086_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1029",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1029_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1029_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1033",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1033_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1033_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1043",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1043_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1043_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1044",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1044_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1044_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1600",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1600_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.1600_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2432",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2432_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2432_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2599",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2599_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2599_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2600",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2600_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2600_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2652",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2652_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2652_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2668",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2668_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2668_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2695",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2695_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.2695_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.3094",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.3094_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.3094_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.59",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.59_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.59_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq03",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq03_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq03_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq08",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq08_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq08_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq14",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq14_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq14_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq16",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq16_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq16_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq27",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq27_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq27_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq48",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq48_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.cq48_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef05",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef05_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef05_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef07",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef07_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef07_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef12",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef12_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef12_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef16",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef16_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef16_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef23",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef23_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef23_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef29",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef29_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef29_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef40",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef40_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef40_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef48",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef48_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef48_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef60",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef60_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef60_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef85",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef85_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef85_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef95",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef95_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.ef95_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.jf49",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.jf49_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.jf49_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.jf82",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.jf82_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.jf82_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.jf93",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.jf93_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            },
            {
              "label": "carbonSteelHeat.chemicalAnalysis.jf93_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "chemicalAnalysisGroup": true
            }
          ]
        }
      ]
    },
    {
      "label": "tensile_test",
      "isForPipeView": false,
      "isForTestView": true,
      "keys": [
        {
          "label": "carbonSteelHeat.tensileTest.type",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "tensileTestGroup": true
        },
        {
          "label": "carbonSteelHeat.tensileTest.testtemperature",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "tensileTestGroup": true
        },
        {
          "label": "carbonSteelHeat.tensileTest.direction",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "tensileTestGroup": true
        },
        {
          "label": "carbonSteelHeat.tensileTest.location",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "tensileTestGroup": true
        },
        {
          "label": "carbonSteelHeat.tensileTest.dimension",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "tensileTestGroup": true
        },
        {
          "label": "carbonSteelHeat.tensileTest.section",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "tensileTestGroup": true
        },
        {
          "label": "carbonSteelHeat.tensileTest.diameter",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "tensileTestGroup": true
        },
        {
          "label": "carbonSteelHeat.tensileTest.thickness",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "tensileTestGroup": true
        },
        {
          "label": "carbonSteelHeat.tensileTest.width",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "tensileTestGroup": true
        },
        {
          "label": "carbonSteelHeat.tensileTest.ys",
          "isForPipeView": false,
          "isForTestView": true,
          "keys": [
            {
              "label": "carbonSteelHeat.tensileTest.ys_rp0_2",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rp0_2_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rp0_2_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rp0_5",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rp0_5_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rp0_5_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rt0_5",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rt0_5_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rt0_5_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rt0_6",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rt0_6_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rt0_6_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rt0_65",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rt0_65_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rt0_65_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rt0_7",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rt0_7_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_rt0_7_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_reh",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_reh_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_reh_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ys_unit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
          ]
        },
        {
          "label": "carbonSteelHeat.tensileTest.ts_title",
          "isForPipeView": false,
          "isForTestView": true,
          "keys": [
            {
              "label": "carbonSteelHeat.tensileTest.ts",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ts_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ts_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ts_unit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.tensileTest.elongation",
          "isForPipeView": false,
          "isForTestView": true,
          "keys": [
            {
              "label": "carbonSteelHeat.tensileTest.elongation_5_65_vso",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.elongation_5_65_vso_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.elongation_5_65_vso_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.elongation_35_56_mm",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.elongation_35_56_mm_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.elongation_35_56_mm_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.elongation_50_8_mm",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.elongation_50_8_mm_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.elongation_50_8_mm_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.elongation_50_mm",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.elongation_50_mm_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.elongation_50_mm_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.elongation_unit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.tensileTest.ratio_title",
          "isForPipeView": false,
          "isForTestView": true,
          "keys": [
            {
              "label": "carbonSteelHeat.tensileTest.ratio",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ratio_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            },
            {
              "label": "carbonSteelHeat.tensileTest.ratio_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "tensileTestGroup": true
            }
          ]
        }
      ]
    },
    {
      "label": "impact_test",
      "isForPipeView": false,
      "isForTestView": true,
      "keys": [
        {
          "label": "carbonSteelHeat.impactTest.type",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "impactTestGroup": true
        },
        {
          "label": "carbonSteelHeat.impactTest.testtemperature",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "impactTestGroup": true
        },
        {
          "label": "carbonSteelHeat.impactTest.direction",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "impactTestGroup": true
        },
        {
          "label": "carbonSteelHeat.impactTest.location",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "impactTestGroup": true
        },
        {
          "label": "carbonSteelHeat.impactTest.dimension",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "impactTestGroup": true
        },
        {
          "label": "carbonSteelHeat.impactTest.section",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "impactTestGroup": true
        },
        {
          "label": "carbonSteelHeat.impactTest.height",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "impactTestGroup": true
        },
        {
          "label": "carbonSteelHeat.impactTest.width",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": false,
          "isForTestView": true,
          "impactTestGroup": true
        },
        {
          "label": "carbonSteelHeat.impactTest.impact",
          "isForPipeView": false,
          "isForTestView": true,
          "keys": [
            {
              "label": "carbonSteelHeat.impactTest.impact1",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.impact1_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.impact1_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.impact2",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.impact2_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.impact2_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.impact3",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.impact3_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.impact3_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.mean",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.mean_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.mean_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.impact1_unit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            }
          ]
        },
        {
          "label": "carbonSteelHeat.impactTest.shear",
          "isForPipeView": false,
          "isForTestView": true,
          "keys": [
            {
              "label": "carbonSteelHeat.impactTest.shearfr1",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.shearfr1_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.shearfr1_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.shearfr2",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.shearfr2_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.shearfr2_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.shearfr3",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.shearfr3_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.shearfr3_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.shear_mean",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.shear_mean_min",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.shear_mean_max",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            },
            {
              "label": "carbonSteelHeat.impactTest.shearfr1_unit",
              "checkedByDefault": false,
              "hasSpecificDisplay": false,
              "isMandatoryPipeView": false,
              "isMandatoryTestView": false,
              "isForPipeView": false,
              "isForTestView": true,
              "impactTestGroup": true
            }
          ]
        }
      ]
    },
         {
           "label": "hardness_test",
           "isForPipeView": false,
           "isForTestView": true,
           "keys": [
             {
               "label": "carbonSteelHeat.hardnessTest.type",
               "checkedByDefault": false,
               "hasSpecificDisplay": false,
               "isMandatoryPipeView": false,
               "isMandatoryTestView": false,
               "isForPipeView": false,
               "isForTestView": true,
               "hardnessTestGroup": true
             },
             {
               "label": "carbonSteelHeat.hardnessTest.reading_outer_surface",
               "isForPipeView": false,
               "isForTestView": true,
               "keys": [
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_outer_surface_1",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_outer_surface_1_min",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_outer_surface_1_max",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_outer_surface_2",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_outer_surface_2_min",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_outer_surface_2_max",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_outer_surface_3",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_outer_surface_3_min",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_outer_surface_3_max",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_outer_surface_avg",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_outer_surface_avg_min",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_outer_surface_avg_max",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_outer_surface_1_unit",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 }
               ]
             },
             {
               "label": "carbonSteelHeat.hardnessTest.reading_mid_wall",
               "isForPipeView": false,
               "isForTestView": true,
               "keys": [
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_mid_wall_1",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_mid_wall_1_min",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_mid_wall_1_max",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_mid_wall_2",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_mid_wall_2_min",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_mid_wall_2_max",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_mid_wall_3",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_mid_wall_3_min",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_mid_wall_3_max",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_mid_wall_avg",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_mid_wall_avg_min",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_mid_wall_avg_max",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_mid_wall_1_unit",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 }
                 ]
                 },
             {
               "label": "carbonSteelHeat.hardnessTest.reading_inner_surface",
               "isForPipeView": false,
               "isForTestView": true,
               "keys": [
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_inner_surface_1",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_inner_surface_1_min",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_inner_surface_1_max",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_inner_surface_2",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_inner_surface_2_min",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_inner_surface_2_max",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_inner_surface_3",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_inner_surface_3_min",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_inner_surface_3_max",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_inner_surface_avg",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_inner_surface_avg_min",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_inner_surface_avg_max",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
                 {
                   "label": "carbonSteelHeat.hardnessTest.reading_inner_surface_1_unit",
                   "checkedByDefault": false,
                   "hasSpecificDisplay": false,
                   "isMandatoryPipeView": false,
                   "isMandatoryTestView": false,
                   "isForPipeView": false,
                   "isForTestView": true,
                   "hardnessTestGroup": true
                 },
               ]
             }
           ]

    },
    {
      "label": "inspection_certificate",
      "isForPipeView": true,
      "isForTestView": false,
      "keys": [
        {
          "label": "carbonSteelCertificate.filename",
          "checkedByDefault": false,
          "hasSpecificDisplay": false,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
        {
          "label": "carbonSteelCertificate.downloadCertificateButton",
          "checkedByDefault": true,
          "hasSpecificDisplay": true,
          "isMandatoryPipeView": false,
          "isMandatoryTestView": false,
          "isForPipeView": true,
          "isForTestView": false
        },
      ]
    },
  ]
}
