/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState, useMemo } from 'react';
import {DropzoneAreaBase} from 'material-ui-dropzone';

import { makeStyles, 
    Theme,
    MenuItem,
    TextField,
    Switch,
    Card, 
    CardHeader, 
    CardActions, 
    CardContent, 
    IconButton } from '@material-ui/core';

import { AppContext } from '../stores/AppStore';
import { UserContext } from '../stores/UserStore';
import { OctgContext } from '../stores/OctgStore';

import requests from '../api/api';

import PageContainer from '../components/layout/PageContainer';
import PageBody from '../components/layout/PageBody';
import PageToolbar from '../components/layout/PageToolbar';
import LoadingOverlay from '../components/common/LoadingOverlay';

import Button from '../components/common/Button';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
        paddingLeft: theme.spacing(35),
        paddingRight: theme.spacing(35),    
    },
    card: {
        width: '100%'
    },
    row: {
        display: 'flex', 
        flexDirection: 'row'
    },
    textField: {
        width:  'calc(100% - 20px)',
        margin: theme.spacing(1)
    },
    switchContainer: {
        display: 'flex', 
        flexDirection: 'column',
        width:  'calc(100% - 20px)',
        margin: theme.spacing(0)
    },
    switchRow: {
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    switchField: {
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width:  '200px'
    }
}));


const OctgUploadNominalData = () => {
    const classes = useStyles();
    const { showSuccessMessage, showErrorMessage } = useContext(AppContext);
    const { currentUser } = useContext(UserContext);
    const { octgNominalState, octgNominalDispatch } = useContext(OctgContext)
    const [isLoading, setLoading] = useState(false);
    
    useEffect(() =>  {
        init();
    }, []);

    const init = () => {
        if (currentUser && currentUser.roles.OCTG_DATA_VIEWER && currentUser.roles.OCTG_DATA_VIEWER.DATA_UPLOADER) {
            setLoading(true);
            const promisse1 = requests.getPlants();
            const promisse2 = requests.getClients();

            Promise.all([promisse1, promisse2]).then((data) => {
                if (data) {
                    const record = {...octgNominalState.octgNominalUploadFields};
                    if (data[0] && data[0].length > 0) {
                        octgNominalDispatch({ type: 'set_plants', plants: data[0] });
                        record.plantId = data[0][0].id;
                    } 
                    if (data[1] && data[1].length > 0) {
                        octgNominalDispatch({ type: 'set_clients', clients: data[1] });
                        record.clientId = data[1][0].id;
                    }                    
                    octgNominalDispatch({ type: 'set_octg_nominal_upload_fields', octgNominalUploadFields: record });                    
                }
            }).catch(error => {
                showErrorMessage(error.message);
            }).finally(() => {
                setLoading(false);            
            });                        
        } else {
            setLoading(false);            
            showErrorMessage('The user doesn´t have permissions to see this informations!');
        }    
    }          

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const record = {...octgNominalState.octgNominalUploadFields};
        record[event.target.name] = event.target.value;
        octgNominalDispatch({ type: 'set_octg_nominal_upload_fields', octgNominalUploadFields: record });
    };        

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const record = {...octgNominalState.octgNominalUploadFields};
        record[event.target.name] = event.target.checked;
        octgNominalDispatch({ type: 'set_octg_nominal_upload_fields', octgNominalUploadFields: record });
    };     

    const handleClickDownloadTemplate = () => {
        window.open('/docs/pipe_data_nominal_data_template.xlsx', '_blank');
    };

    const handleClickUpload = () => {
        const file = octgNominalState.octgNominalUploadFiles[0].file;
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
    
        const params = {
            clientId: octgNominalState.octgNominalUploadFields.clientId,
            plantId: octgNominalState.octgNominalUploadFields.plantId,
            fileType: file.type,
            fileName: file.name,
            title: octgNominalState.octgNominalUploadFields.loadTitle,
            data: '',
            weightUnit: octgNominalState.octgNominalUploadFields.weightUnit,
            lengthUnit: octgNominalState.octgNominalUploadFields.lengthUnit

        }

        reader.onload = async(e: ProgressEvent) => {
            setLoading(true);
            const byteArray = (e.target as FileReader).result;
            const base64 = transformFileToBase64(byteArray);

            const buffer = Buffer.from(base64, 'base64');
            console.log(buffer);


            params.data = base64;

            requests.uploadNominalData(params).then((data) => {
                octgNominalDispatch({ type: 'set_octg_nominal_upload_files', octgNominalUploadFiles: [] });
                showSuccessMessage('Upload Data Success!');
            }).catch(error => {
                showErrorMessage(error.message);
            }).finally(() => {
                setLoading(false);            
            });
        };                   
    };

    const transformFileToBase64 = (content: any) => {
        let binary = '';
        const bytes = new Uint8Array(content);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        const base64 = btoa(binary);
        return base64;
    }
    
    const renderForm = useMemo(() => <Card className={classes.card}>
        <CardHeader
            title="Enter Information"
            action={
                <IconButton data-cy="downloadBtn" component="span" aria-label="settings" onClick={handleClickDownloadTemplate}>
                    <InfoIcon />
                </IconButton>
            }
        />                            
        <CardContent>
            <div className={classes.row}>
                <TextField
                    label="Load Title:"
                    required
                    placeholder="Load Title"
                    id="loadTitle"
                    name="loadTitle"
                    className={classes.textField}
                    error={octgNominalState.octgNominalUploadFields.loadTitle !== undefined && !octgNominalState.octgNominalUploadFields.loadTitle}
                    value={octgNominalState.octgNominalUploadFields.loadTitle}
                    onChange={handleInputChange}                            
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    InputLabelProps={{shrink: true}}
                    />

                <TextField
                    select
                    label="Plant:"
                    required
                    placeholder="Plant Type"
                    id="plantId"
                    name="plantId"
                    className={classes.textField}
                    error={octgNominalState.octgNominalUploadFields.plantId !== undefined && !octgNominalState.octgNominalUploadFields.plantId}
                    value={octgNominalState.octgNominalUploadFields.plantId}
                    onChange={handleInputChange}                            
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    InputLabelProps={{shrink: true}}>
                        {   
                            octgNominalState.plants.map((plant) => (
                                <MenuItem key={plant.id} value={plant.id}>
                                    {plant.name}                                                
                                </MenuItem>
                            ))                                    
                        }
                </TextField>
            </div>
            <div className={classes.row}>
                <TextField
                    select
                    label="End User:"
                    required
                    placeholder="End User"
                    id="clientId"
                    name="clientId"
                    className={classes.textField}
                    error={octgNominalState.octgNominalUploadFields.clientId !== undefined && !octgNominalState.octgNominalUploadFields.clientId}
                    value={octgNominalState.octgNominalUploadFields.clientId}
                    onChange={handleInputChange}                            
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    InputLabelProps={{shrink: true}}>
                        {   
                            octgNominalState.clients.map((client) => (
                                <MenuItem key={client.id} value={client.id}>
                                    {client.name}                                                
                                </MenuItem>
                            ))                                    
                        }
                </TextField>

                
                <div className={classes.switchContainer}>
                    <div className={classes.switchRow}>
                        <b>Select Units</b>
                    </div>
                    <div className={classes.switchRow}>
                        <div className={classes.switchField}>
                            <b>lb/ft</b>
                            <Switch 
                                data-cy="weightUnit" 
                                name="weightUnit" 
                                checked={octgNominalState.octgNominalUploadFields.weightUnit}
                                onChange={handleSwitchChange}  
                                color="primary" />
                            <b>kg/m</b>
                        </div>

                        <div className={classes.switchField}>
                            <b>in</b>
                            <Switch 
                                data-cy="lengthUnit" 
                                name="lengthUnit" 
                                checked={octgNominalState.octgNominalUploadFields.lengthUnit}
                                onChange={handleSwitchChange}  
                                color="primary" />
                            <b>mm</b>
                        </div>                        
                    </div>                    
                </div>
                

            </div>
        </CardContent>
    </Card>, [octgNominalState.octgNominalUploadFields]);

    const renderUpload = useMemo(() => <Card className={classes.card}>
        <CardContent data-cy="cardContent">
            <DropzoneAreaBase 
                dropzoneText="Click or drag a Nominal Data file to upload"
                acceptedFiles={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"]}
                maxFileSize={6291456}//6mb
                showFileNames={true}
                useChipsForPreview={true}
                filesLimit={1}
                fileObjects={octgNominalState.octgNominalUploadFiles}
                onAdd={newFileObjs => {
                    octgNominalDispatch({ type: 'set_octg_nominal_upload_files', octgNominalUploadFiles: newFileObjs });
                }}
                onDelete={deleteFileObj => {
                    octgNominalDispatch({ type: 'set_octg_nominal_upload_files', octgNominalUploadFiles: [] });
                }}
            />
        </CardContent>
        <CardActions>
            <Button data-cy="sendFile" disabled={octgNominalState.octgNominalUploadFiles.length === 0 || octgNominalState.octgNominalUploadFields.loadTitle === undefined || octgNominalState.octgNominalUploadFields.plantId === undefined || octgNominalState.octgNominalUploadFields.clientId === undefined } variant="contained" color="primary" size="small" startIcon={<CloudUploadIcon />} onClick={handleClickUpload} >
                Send File
            </Button>
        </CardActions>
    </Card>, [octgNominalState.octgNominalUploadFiles, octgNominalState.octgNominalUploadFields]);

    return (
        <PageContainer className={classes.root}>
            <PageToolbar title="Upload Nominal Data" backButton={true}></PageToolbar>
            {currentUser && currentUser && currentUser.roles.OCTG_DATA_VIEWER && currentUser.roles.OCTG_DATA_VIEWER.DATA_UPLOADER && <PageBody>
                {renderForm} 
                <br/>     
                {renderUpload}      
            </PageBody> }
            <LoadingOverlay open={isLoading} />
        </PageContainer>
    );
};

export default OctgUploadNominalData;
