import React, { useReducer, Dispatch } from 'react';

type State = {
  loadColumns: any[]; 
  loadData: any[];
  loadDetails: any[];
}

type Action =
  | { type: 'set_load_columns'; loadColumns: any[] }
  | { type: 'set_load_data'; loadData: any[] }
  | { type: 'set_load_details'; loadDetails: any[] }

const initialState: State = {
  loadColumns: [],
  loadData: [],
  loadDetails: [],
};

export const LoadContext = React.createContext<{
  loadState: State;
  loadDispatch: Dispatch<Action>;
}>({
  loadState: initialState,
  loadDispatch: () => null
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'set_load_columns':
      return {
        ...state,
        loadColumns: action.loadColumns,
      }
    case 'set_load_data':
      return {
        ...state,
        loadData: action.loadData,
      }
    case 'set_load_details':
      return {
        ...state,
        loadDetails: action.loadDetails,
      }
    }
}


export const LoadProvider = (props: any) => {
  const [loadState, loadDispatch] = useReducer(reducer, initialState);

  return <LoadContext.Provider value={{ loadState, loadDispatch }}>
    {props.children}
  </LoadContext.Provider>;
}