import React, {useContext, useEffect} from 'react';

import {makeStyles, Theme, Container, Grid, Typography, Card, CardActionArea, CardMedia, CardContent, IconButton, Avatar} from '@material-ui/core';

import {AppContext} from '../stores/AppStore';
import { UserContext } from '../stores/UserStore';

import ArrowBackIcon from '@material-ui/icons/ArrowBackSharp';
import NominalDataIcon from '../assets/icons/cloud.svg';
import UploadNominalDataIcon from '../assets/icons/data-transfer.svg';
import ActualDataIcon from '../assets/icons/cloud-actual.svg';
import UploadActualDataIcon from '../assets/icons/data-transfer-actual.svg';
import OCTGIcon from '../assets/icons/octg.svg';

import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    titleContainer: {
        marginBottom: theme.spacing(0),
        marginTop: theme.spacing(1),
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center'       
    },
    titleHead: {
        color: 'rgb(89, 203, 228)',
        fontSize: 32,
        fontWeight: 700,
        marginRight: 0,
        marginLeft: 20,
    },
    card: {
        maxWidth: 345,
    },
    card_action: {
        '&.MuiButtonBase-root.Mui-disabled': {
            backgroundColor: '#dddddd47'
        }   
    },
    card_media: {
        height: '60px',
        margin: '40px 10px 10px',
        backgroundSize: 'contain'
    }
}));

const OctgHome = () => {
    const classes = useStyles();
    const {setPageSubtitle} = useContext(AppContext);
    const { currentUser } = useContext(UserContext);

    const history = useHistory();

    useEffect(() => {
        setPageSubtitle('');
    }, [setPageSubtitle]);

    const onCardClick = (link) => () => {
        history.push(link);
    };

    const onBackClick = () => {
        history.goBack()
    }    

    return (
        <Container className={classes.root} maxWidth="md">
            <Grid container spacing={3}>
                <Grid item md={2}></Grid>
                <Grid item className={classes.titleContainer} xs={6}>
                    <IconButton color="primary" component="span" onClick={onBackClick}>
                        <ArrowBackIcon fontSize="large" />
                    </IconButton> 
                    <Avatar alt="OCTG" src={OCTGIcon} />
                    <Typography><span className={classes.titleHead}>OCTG</span></Typography>
                </Grid>
            </Grid>
            { currentUser && <Grid container spacing={2}>
                <Grid item md={2}></Grid>
                <Grid item md={4} sm={12}>
                    <Card className={classes.card}>
                        <CardActionArea disabled={!currentUser.roles.OCTG_DATA_VIEWER || !(currentUser.roles.OCTG_DATA_VIEWER.DATA_APPROVER || currentUser.roles.OCTG_DATA_VIEWER.DATA_UPLOADER)} className={classes.card_action} onClick={onCardClick('/octg/view/nominal-data')}>
                            <CardMedia
                                className={classes.card_media}
                                image={NominalDataIcon}
                                title="VIEW NOMINAL DATA"
                            />
                            <CardContent>
                                <Typography align="center" variant="h6" color="textSecondary" component="h2">
                                    VIEW NOMINAL DATA
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item md={4} sm={12}>
                    <Card className={classes.card}>
                        <CardActionArea disabled={!currentUser.roles.OCTG_DATA_VIEWER || !currentUser.roles.OCTG_DATA_VIEWER.DATA_UPLOADER} className={classes.card_action} onClick={onCardClick('/octg/upload/nominal-data')}>
                            <CardMedia
                                className={classes.card_media}
                                image={UploadNominalDataIcon}
                                title="UPLOAD NOMINAL DATA"
                            />
                            <CardContent>
                                <Typography align="center" variant="h6" color="textSecondary" component="h2">
                                    UPLOAD NOMINAL DATA
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item md={2}></Grid>
                <Grid item md={2}></Grid>
                <Grid item md={4} sm={12}>
                    <Card className={classes.card}>
                        <CardActionArea disabled={!currentUser.roles.OCTG_DATA_VIEWER || !(currentUser.roles.OCTG_DATA_VIEWER.DATA_APPROVER || currentUser.roles.OCTG_DATA_VIEWER.DATA_UPLOADER)} className={classes.card_action} onClick={onCardClick('/octg/view/actual-data')}>
                            <CardMedia
                                className={classes.card_media}
                                image={ActualDataIcon}
                                title="VIEW ACTUAL DATA"
                            />
                            <CardContent>
                                <Typography align="center" variant="h6" color="textSecondary" component="h2">
                                    VIEW ACTUAL DATA
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item md={4} sm={12}>
                    <Card className={classes.card}>
                        <CardActionArea disabled={!currentUser.roles.OCTG_DATA_VIEWER || !currentUser.roles.OCTG_DATA_VIEWER.DATA_UPLOADER} className={classes.card_action} onClick={onCardClick('/octg/upload/actual-data')}>
                            <CardMedia
                                className={classes.card_media}
                                image={UploadActualDataIcon}
                                title="UPLOAD ACTUAL DATA"
                            />
                            <CardContent>
                                <Typography align="center" variant="h6" color="textSecondary" component="h2">
                                    UPLOAD ACTUAL DATA
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item md={2}></Grid>                
            </Grid> }
        </Container>
    );
};

export default OctgHome;
