/** @format */

import { makeStyles } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import requests from '../../api/api'
import Multiselect from '../../components/common/Multiselect'
import { AppContext } from '../../stores/AppStore'
import { PlppContext } from '../../stores/PlppStore'
import { UserContext } from '../../stores/UserStore'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
	multiselects: {
		marginLeft: theme.spacing(3)
	}
}))
function useQuery() {
	const { search } = useLocation();
	let res = React.useMemo(() => new URLSearchParams(search), [search]);
	return res
}
const ProjectAndMaterialSelect = (props: { isPipeView: boolean }) => {
	const classes = useStyles()

	const { setPageLoading } = useContext(AppContext)
	const { plppState, plppDispatch } = useContext(PlppContext)
	const { setAuthState, currentUser } = useContext(UserContext)
	const [selectedOrderIds, setSelectedOrderIds] = React.useState<string[]>(plppState.selectedOrderIds)
	const [selectedItemIds, setSelectedItemIds] = React.useState<string[]>(plppState.selectedItemIds)
	let dataForSelectedItems: any[] = []
	let testsDataForSelectedItems: any[] = []

	const { t } = useTranslation()

	console.log()

	useEffect(() => {
		plppDispatch({ type: 'set_is_need_to_refresh_plpp_data', isNeedToRefreshPlppData: true })
		requests
			.getOrderData(currentUser.id)
			.then((data) => {
				plppDispatch({
					type: 'set_all_orders',
					allOrders: data,
				});
			})
			.catch((e) => {
				if (e.resultCode === 'error.expired.token') {
					setAuthState('LOGIN');
				} else {
					toast.error(e.message);
				}
			});
	}, [])

	let query = useQuery();

	useEffect(() => {
		if (plppState.selectedItemIds.length === 0) {
			if (query.get("orders")) {
				const orderIds = query.get("orders").split(',')
				setSelectedOrderIds(orderIds)
				if (orderIds.length > 0) {
					plppDispatch({
						type: 'init_selected_order_ids',
						selectedOrderIds: orderIds
					})
				}
			}
		}
	}, [plppState.allOrders])

	useEffect(() => {
		if (plppState.selectedItemIds.length === 0) {
			if (query.get("orders")) {
				const orderIds = query.get("orders").split(',')
				const allItemsForSelectedOrders = plppState.allOrders
					.filter(order => orderIds.includes(order.vallourecOrderReference))
					.map(order => order.items)
					.flat()
				setSelectedItemIds([...allItemsForSelectedOrders.map(item => item.id)])
				if (allItemsForSelectedOrders.length > 0) {
					plppDispatch({
						type: 'init_selected_item_ids',
						selectedItemIds: allItemsForSelectedOrders.map(item => item.id)
					})
				}
				plppDispatch({ type: 'set_is_first_loading', isFirstLoading: true })
				plppDispatch({ type: 'set_is_need_to_refresh_plpp_columns', isNeedToRefreshPlppColumns: true })
				plppDispatch({ type: 'set_is_need_to_refresh_plpp_data', isNeedToRefreshPlppData: true })
			}
		}
	}, [plppState.selectedOrderIds])

	const handleApplyOrders = async (values: any[], getId: boolean = true) => {
		setPageLoading(true)
		const orderIds = getId ? getIds(values) : values
		const allItemsForSelectedOrders = plppState.allOrders
			.filter(order => orderIds.includes(order.id))
			.map(order => order.items)
			.flat()
		const itemIds = plppState.selectedItemIds.filter(itemId =>
			allItemsForSelectedOrders.find(item => item.id === itemId)
		)
		setSelectedOrderIds(orderIds)
		setSelectedItemIds(itemIds)

		if (plppState.allPlppData.length > 0 && plppState.allTestsPlppData.length > 0) {
			// If all data is loaded, no need to call the api, we filter on order
			plppDispatch({
				type: 'set_selected_order_ids_from_all_plpp_data',
				selectedOrderIds: orderIds
			})
		} else {
			if (orderIds.length > 0) {
				// If some orders are selected, refresh the items list
				plppDispatch({
					type: 'init_selected_order_ids',
					selectedOrderIds: orderIds
				})
			} else {
				// If no orders are selected, refresh the items list with no items
				plppDispatch({
					type: 'init_selected_order_ids',
					selectedOrderIds: []
				})
			}
		}

		setPageLoading(false)
	}

	const handleApplyItems = async (values: any[]) => {
		setPageLoading(true)
		const itemIds = getIds(values)
		const equalArray = itemIds.length === selectedItemIds.length &&
			selectedItemIds.every((v, i) => v === itemIds[i])
		if (!equalArray) {
			setSelectedItemIds(itemIds)
			if (plppState.allPlppData.length > 0 && plppState.allTestsPlppData.length > 0) {
				// If all data is loaded, no need to call the api, we filter on item
				plppDispatch({
					type: 'set_selected_item_ids_from_all_plpp_data',
					selectedItemIds: itemIds
				})
				setPageLoading(false)
			} else {
				// Else, we call the api and refresh the table
				plppDispatch({
					type: 'init_selected_item_ids',
					selectedItemIds: itemIds
				})
				if (itemIds.length) {
					getPlppDataForItems(itemIds)
					getTestsPlppDataForItems(itemIds)
				} else {
					// If no item selected, display an empty array
					plppDispatch({
						type: 'set_selected_item_ids_from_all_plpp_data',
						selectedItemIds: []
					})
					setPageLoading(false)
				}
			}
		} else {
			setPageLoading(false)
		}
	}

	const getPlppDataForItems = (selectedItemIds: string[]) => {
		dataForSelectedItems = []
		return requests
			.getPlppData(selectedItemIds)
			.then(data => {
				const total = data[1]
				let current = data[0].length
				const packetSize = 1000
				let buffer = data[0]

				return getPlppDataByPacket(current, total, packetSize, buffer, selectedItemIds)
			})
			.finally(() => {
				if (props.isPipeView) {
					setPageLoading(false)
				}
			})
			.catch(e => {
				if (e.resultCode === 'error.expired.token') {
					setAuthState('LOGIN')
				} else {
					toast.error(e.message)
				}
			})
	}

	const getTestsPlppDataForItems = (selectedItemIds: string[]) => {
		testsDataForSelectedItems = []
		const packetSize = 10
		return requests
			.getTestsPlppDataOffset(0, packetSize, selectedItemIds)
			.then(data => {
				const total = data[1]
				const current = packetSize
				let buffer = data[0].map(carbonSteelHeat => ({ carbonSteelHeat }))

				return getTestsPlppDataByPacket(current, total, packetSize, buffer, selectedItemIds)
			})
			.finally(() => {
				if (!props.isPipeView) {
					setPageLoading(false)
				}
			})
			.catch(e => {
				if (e.resultCode === 'error.expired.token') {
					setAuthState('LOGIN')
				} else {
					toast.error(e.message)
				}
			})
	}

	const getPlppDataByPacket = (
		current: number,
		total: number,
		packetSize: number,
		buffer: any[],
		selectedItemIds?: string[]
	) => {
		return requests.getPlppDataOffset(current, packetSize, selectedItemIds).then(nextCall => {
			current = current + nextCall[0].length
			buffer = buffer.concat(nextCall[0])
			if (current < total) {
				return getPlppDataByPacket(current, total, packetSize, buffer, selectedItemIds)
			} else {
				return treatPlppDataResult(buffer)
			}
		})
	}

	const getTestsPlppDataByPacket = (
		current: number,
		total: number,
		packetSize: number,
		buffer: any[],
		selectedItemIds?: string[]
	) => {
		return requests.getTestsPlppDataOffset(current, packetSize, selectedItemIds).then(nextCall => {
			current = current + packetSize
			buffer = buffer.concat(nextCall[0].map(carbonSteelHeat => ({ carbonSteelHeat })))
			if (current < total) {
				return getTestsPlppDataByPacket(current, total, packetSize, buffer, selectedItemIds)
			} else {
				return treatTestsPlppDataResult(buffer)
			}
		})
	}

	const treatPlppDataResult = (data: any[]) => {
		if (data.length > 0) {
			dataForSelectedItems = dataForSelectedItems.concat(data)
			plppDispatch({
				type: 'set_displayed_plpp_data_and_refresh',
				displayedPlppData: dataForSelectedItems
			})
		}
	}

	const treatTestsPlppDataResult = (data: any[]) => {
		if (data.length > 0) {
			testsDataForSelectedItems = testsDataForSelectedItems.concat(data)
			plppDispatch({
				type: 'set_displayed_tests_plpp_data_and_refresh',
				displayedTestsPlppData: testsDataForSelectedItems
			})
		}
	}

	return (
		<div className={classes.multiselects}>
			<Multiselect
				handleApply={handleApplyOrders}
				inputLabel={t('plpp-data.Project')}
				selectedOptions={plppState.allOrders.filter(order => selectedOrderIds.includes(order.id) || selectedOrderIds.includes(order.vallourecOrderReference))}
				allOptions={plppState.allOrders}
			/>
			<Multiselect
				handleApply={handleApplyItems}
				inputLabel={t('plpp-data.Material')}
				selectedOptions={
					selectedOrderIds.length
						? plppState.allItemsForSelectedOrders.filter(item => selectedItemIds.includes(item.id))
						: []
				}
				allOptions={selectedOrderIds.length ? plppState.allItemsForSelectedOrders : []}
			/>
		</div>
	)
}

export default ProjectAndMaterialSelect

function getIds(objects: string[]) {
	return objects.map((object: any) => object.id)
}
