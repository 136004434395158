/** @format */

import { IconButton } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import { MTableToolbar } from 'material-table'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import requests from '../../api/api'
import FilterableTable from '../../components/common/FilterableTable'
import LoadingOverlay from '../../components/common/LoadingOverlay'
import { AppContext } from '../../stores/AppStore'
import { PlppContext } from '../../stores/PlppStore'
import { UserContext } from '../../stores/UserStore'
import ColumnsOrdering from './manageColumns/ColumnsOrdering'
import PopoverFilter from './PopoverFilter'
import ChipsFilters from '../../components/common/ChipsFilters'

const PlppDataTable = (props: { switchToTestView: () => void }) => {
	const { pageLoading, setPageLoading } = useContext(AppContext)
	const { plppState, plppDispatch } = useContext(PlppContext)
	const { currentUser, setAuthState } = useContext(UserContext)
	const { t } = useTranslation()
	const [selectedItemIds, setSelectedItemIds] = useState<string[]>(plppState.selectedItemIds)

    let initData: any[] = []
	let globalData: any[] = []
	let initTestsData: any[] = []
	let globalTestsData: any[] = []

		var defaultVisibleColumns: string[] = []

		const downloadCertificate = (valid?: string, tallyNumber?: string, orderNumber?: string, itemNumber?: string) => {
			setPageLoading(true)
			if (valid) {
				requests
					.getCertificatePlppData(valid)
					.then(data => {
						window.open(data)
					})
					.finally(() => {
						setPageLoading(false)

						// Scroll to the clicked button
						let rows = Array.from(document.getElementsByTagName('tr'))
						for (var i = 0; i < rows.length; i++) {
							if (rows[i].textContent.includes(valid)) {
								let columns = Array.from(rows[i].getElementsByTagName('td'))
								for (var y = 0; y < columns.length; y++) {
									if (columns[y].getElementsByTagName('button').length > 0) {
										columns[y].scrollIntoView()
										break
									}
								}
								break
							}
						}
					})
					.catch(e => {
						setPageLoading(false)
						if (e.resultCode === 'error.expired.token') {
							setAuthState('LOGIN')
						} else {
							toast.error(t('plpp-data.noCertificate').toString())
						}
					})
			} else if (tallyNumber && orderNumber && itemNumber) {
				requests
					.getCertificatePlppDataByTally(tallyNumber, orderNumber, itemNumber)
					.then(data => {
						window.open(data)
					})
					.finally(() => {
						setPageLoading(false)

						// Scroll to the clicked button
						let rows = Array.from(document.getElementsByTagName('tr'))
						for (var i = 0; i < rows.length; i++) {
							if (rows[i].textContent.includes(valid)) {
								let columns = Array.from(rows[i].getElementsByTagName('td'))
								for (var y = 0; y < columns.length; y++) {
									if (columns[y].getElementsByTagName('button').length > 0) {
										columns[y].scrollIntoView()
										break
									}
								}
								break
							}
						}
					})
					.catch(e => {
						setPageLoading(false)
						if (e.resultCode === 'error.expired.token') {
							setAuthState('LOGIN')
						} else {
							toast.error(t('plpp-data.noCertificate').toString())
						}
					})
			}
		}

		const retrieveColumnsToDisplay = () => {
			return requests
				.getAllTemplatesByUserData(currentUser.id)
				.then(templates => {
					const defaultTemplate = templates.find(template => template.name === 'Default template')
					if (defaultTemplate) {
						defaultVisibleColumns = defaultTemplate.carbonSteelColumns.map(column => column.label)
					} else {
						// Retrieve default selected columns from ColumnsOrdering
						defaultVisibleColumns = ColumnsOrdering.sections.flatMap(section => {
							return section.keys
								.flatMap(key => {
									if (key.keys && key.keys.length > 0) {
										return key.keys
									} else {
										return key
									}
								})
								.filter(key => key.checkedByDefault === true)
								.map(key => key.label)
						})
					}

					// Dispatch the list of selected columns in the context
					plppDispatch({
						type: 'set_selected_plpp_columns',
						selectedColumns: defaultVisibleColumns
					})
				})
				.catch(e => {
					if (e.resultCode === 'error.expired.token') {
						setAuthState('LOGIN')
					} else {
						toast.error(e.message)
					}
				})
		}

		const getPlppDataByPacket = (
			current: number,
			total: number,
			packetSize: number,
			buffer: any[],
			selectedItemIds?: string[]
		) => {
			return requests.getPlppDataOffset(current, packetSize, selectedItemIds).then(nextCall => {
				current = current + nextCall[0].length
				buffer = buffer.concat(nextCall[0])
				if (current < total) {
					return getPlppDataByPacket(current, total, packetSize, buffer, selectedItemIds)
				} else {
					return selectedItemIds == null ? treatAllPlppDataResult(buffer) : treatPlppDataResult(buffer)
				}
			})
		}

		const getTestsPlppDataByPacket = (
			current: number,
			total: number,
			packetSize: number,
			buffer: any[],
			selectedItemIds?: string[]
		) => {
			return requests
				.getTestsPlppDataOffset(current, packetSize, selectedItemIds)
				.then(nextCall => {
					current = current + nextCall[0].length
					buffer = buffer.concat(nextCall[0].map(carbonSteelHeat => ({ carbonSteelHeat })))
					if (current < total) {
						return getTestsPlppDataByPacket(current, total, packetSize, buffer, selectedItemIds)
					} else {
						return selectedItemIds == null
							? treatAllTestsPlppDataResult(buffer)
							: treatTestsPlppDataResult(buffer)
					}
				})
				.finally(() => {
					plppDispatch({
						type: 'set_tests_data_to_display_have_been_loaded'
					})
				})
		}

		const treatAllPlppDataResult = (data: any[]) => {
			if (data.length > 0) {
				globalData = globalData.concat(data)
				plppDispatch({
					type: 'set_all_plpp_data',
					allPlppData: globalData
				})
			}
		}

		const treatAllTestsPlppDataResult = (data: any[]) => {
			if (data.length > 0) {
				globalTestsData = globalTestsData.concat(data)
				plppDispatch({
					type: 'set_all_tests_plpp_data',
					allTestsPlppData: globalTestsData
				})
			}
		}

		const treatPlppDataResult = (data: any[]) => {
			updatePlppColumns()
			if (data.length > 0) {
				initData = initData.concat(data)
				plppDispatch({
					type: 'set_displayed_plpp_data',
					displayedPlppData: initData
				})
				setPageLoading(false)
				return
			}
		}

		const treatTestsPlppDataResult = (data: any[]) => {
			if (data.length > 0) {
				initTestsData = initTestsData.concat(data)
				plppDispatch({
					type: 'set_displayed_tests_plpp_data',
					displayedTestsPlppData: initTestsData
				})
				return
			}
		}

		const updatePlppColumns = () => {
			const allKeys = ColumnsOrdering.sections.flatMap(section => {
				return section.keys
					.flatMap(key => {
						if (key.keys && key.keys.length > 0) {
							return key.keys
						} else {
							return key
						}
					})
					.filter(key => key.hasSpecificDisplay === false)
			})

			const plppColumns: any[] = allKeys.map(key => {
				return {
					title: t('plpp-data.' + key.label + '.name'),
					field: key.label,
					key: key.label,
					defaultSort: key.defaultSort,
					isForPipeView: key.isForPipeView,
					isForTestView: key.isForTestView,
					isMandatoryPipeView: key.isMandatoryPipeView,
					isMandatoryTestView: key.isMandatoryTestView,
					visible: defaultVisibleColumns.includes(key.label),
					filterComponent: props => (
						<PopoverFilter
							{...props}
							isPipeView={true}
							isAlphanumeric={t('plpp-data.' + key.label + '.filter') === 'alphanumeric'}
							onFilterChanged={onFilterChange(key.label)}
							columnTitle={key.label}
						/>
					)
				}
			})
			plppColumns.push({
				title: t('plpp-data.downloadCertificate'),
				key: 'carbonSteelCertificate.downloadCertificateButton',
				isForPipeView: 'true',
				visible: defaultVisibleColumns.includes('carbonSteelCertificate.downloadCertificateButton'),
				render: (rowData: any) => (
					<IconButton
						onClick={() => {
							downloadCertificate(
								rowData.valid,
								rowData.tallyNumber,
								rowData.carbonSteelItemPlpp && rowData.carbonSteelItemPlpp.carbonSteelOrder
									? rowData.carbonSteelItemPlpp.carbonSteelOrder.vallourecOrderReference
									: undefined,
								rowData.carbonSteelItemPlpp ? rowData.carbonSteelItemPlpp.vallourecItemReference : undefined
							)
						}}
						data-testid="downloadButton"
					>
						<GetApp />
					</IconButton>
				)
			})

			plppDispatch({
				type: 'set_plpp_columns',
				plppColumns
			})
		}

		const onFilterChange = (key: string) => {
			return (columnId: any, paramFilter: any) => {
				const min = paramFilter.min ? paramFilter.min : ''
				const max = paramFilter.max ? paramFilter.max : ''
				const splitValues = paramFilter.value ? paramFilter.value.split('|') : ''

				if (paramFilter.clear || (min === '' && max === '' && splitValues === '')) {
					plppDispatch({
						type: 'remove_filter_from_columns_map',
						filterToRemoveFromPipeView: key,
						filterToRemoveFromTestView: undefined
					})
				} else {
					// construct the filtered map column
					let filteredColumnsMap = new Map()
					filteredColumnsMap.set(key, {
						values: splitValues,
						min: min,
						max: max
					})

					plppDispatch({
						type: 'set_filtered_columns_map',
						filteredColumnsMap,
						filteredTestsColumnsMap: new Map()
					})
				}
			}
		}

		const getPlppData = (isFirstLoading: boolean) => {
			requests
				.getPlppData(selectedItemIds)
				.then(data => {
					// Retrieve columns to be displayed
					return retrieveColumnsToDisplay().then(() => {
						const total = data[1]
						let current = data[0].length
						const packetSize = 1000
						let buffer = data[0]

						return getPlppDataByPacket(current, total, packetSize, buffer, selectedItemIds)
                    })
				})
				.finally(() => {
					setPageLoading(false)
					if (isFirstLoading) {
						requests.getPlppData().then(data => {
							const total = data[1]
							let current = data[0].length
							const packetSize = 1000
							let buffer = data[0]
							getPlppDataByPacket(current, total, packetSize, buffer)
						})
					}
				})
				.catch(e => {
					if (e.resultCode === 'error.expired.token') {
						setAuthState('LOGIN')
					} else {
						toast.error(e.message)
					}
				})
		}

		const getTestsPlppData = (isFirstLoading: boolean) => {
			const packetSize = 10

			requests
				.getTestsPlppDataOffset(0, packetSize, selectedItemIds)
				.then(data => {
					const total = data[1]
					const current = data[0].length
					let buffer = data[0].map(carbonSteelHeat => ({ carbonSteelHeat }))

					return getTestsPlppDataByPacket(current, total, packetSize, buffer, selectedItemIds)
				})
				.finally(() => {
					plppDispatch({ type: 'set_is_finished_loading_tests_plpp_data', isFinishedLoadingTestsPlppData: true })
					if (isFirstLoading) {
						requests.getTestsPlppDataOffset(0, packetSize).then(data => {
							const total = data[1]
							let current = data[0].length
							let buffer = data[0].map(carbonSteelHeat => ({ carbonSteelHeat }))
							getTestsPlppDataByPacket(current, total, packetSize, buffer)
						})
					}
				})
				.catch(e => {
					if (e.resultCode === 'error.expired.token') {
						setAuthState('LOGIN')
					} else {
						toast.error(e.message)
					}
				})
		}

	useEffect(() => {
		if (selectedItemIds.length > 0 && (plppState.isFirstLoading || plppState.isNeedToRefreshPlppData)) {
			setPageLoading(true)
			plppDispatch({ type: 'set_is_first_loading', isFirstLoading: false })
			plppDispatch({ type: 'set_is_need_to_refresh_plpp_data', isNeedToRefreshPlppData: false })
			plppDispatch({ type: 'set_is_need_to_refresh_plpp_columns', isNeedToRefreshPlppColumns: false })
			getPlppData(plppState.isFirstLoading)
			getTestsPlppData(plppState.isFirstLoading)
		} else if (selectedItemIds.length > 0 && plppState.isNeedToRefreshPlppColumns) {
			plppDispatch({ type: 'set_is_need_to_refresh_plpp_columns', isNeedToRefreshPlppColumns: false })
			defaultVisibleColumns = plppState.selectedPlppColumns
			updatePlppColumns()
		}
	}, [plppDispatch, plppState, selectedItemIds, setPageLoading, currentUser, setAuthState, t])

	useEffect(() => {
	        setSelectedItemIds([...plppState.selectedItemIds])
	}, [plppState.selectedItemIds])

	useEffect(() => {
		// CSS to stick header, filter and tally column
		const array = Array.from(document.getElementsByClassName('MuiTableRow-root'))
		array.forEach((lineElement, lineIndex) => {
			const isHeaderElement = lineElement.getElementsByTagName('th').length > 0
			const isFilterElement = lineIndex === 1
			const rowElements = isHeaderElement
				? lineElement.getElementsByTagName('th')
				: lineElement.getElementsByTagName('td')

			// CSS to stick header and filter
			if (isHeaderElement || isFilterElement) {
				Array.from(rowElements).forEach(element => {
					if (isHeaderElement || isFilterElement) {
						element.style.position = 'sticky'
						element.style.top = lineIndex === 0 ? '0px' : (array[0] as HTMLElement).offsetHeight + 'px'
						element.style.zIndex = '998'
						element.style.background = '#FFFFFF'
					}
				})
			}

			// CSS to stick tally column : It's always the first column
			const tallyElement = rowElements[1]
			if (tallyElement) {
				tallyElement.style.position = 'sticky'
				tallyElement.style.left = '0'
				tallyElement.style.zIndex = isHeaderElement || isFilterElement ? '999' : '998'
				tallyElement.style.backgroundColor =
					tallyElement.parentElement.style.backgroundColor === 'rgba(199, 225, 191, 0.5)'
						? 'rgb(227,240,223)'
						: '#FFFFFF'
				tallyElement.style.transition = 'all 300ms ease 0s'
			}
		})
	})

	const getColumnsToDisplay = () => {
		return plppState.plppColumns.filter(column => { return column.visible && column.isForPipeView })
	}

	const getDataToDisplay = () => {
		return plppState.displayedPlppData
	}

	const setSelectedRows = (selectedPlppData: any[]) => {
		plppDispatch({ type: 'set_selected_plpp_data', selectedPlppData })
	}

	return pageLoading ? (
		<LoadingOverlay open={true} />
	) : (
		<FilterableTable
			columns={getColumnsToDisplay()}
			data={getDataToDisplay()}
			currentPageNumber={plppState.pageNumber}
			setCurrentPageNumber={(pageNumber: number) => plppDispatch({ type: 'set_page_number', pageNumber })}
			autoResetFilters={false}
			currentPageSize={plppState.pageSize}
			setCurrentPageSize={(pageSize: number) => plppDispatch({ type: 'set_page_size', pageSize })}
			title={<ChipsFilters isPipeView={true} filters={plppState.filteredColumnsMap} />}
			components={{
				Toolbar: propsToolbar => (
					<div>
						<MTableToolbar {...propsToolbar} />
					</div>
				)
			}}
			selectedData={plppState.selectedPlppData}
			setSelectedRows={setSelectedRows}
		/>
	)
}

export default PlppDataTable
