import { Box, Button, Checkbox, createStyles, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import theme from "../../utils/theme";

export type OptionProps = {
   label: string,
   name: string,
   parent: string,
   hasChild: boolean,
   selectedChildCount?: number,
   checked: boolean,
   disabled: boolean
}

export type ScrollableListWithSearchProps = {
   options: OptionProps[],
   onExpand: any,
   onCheck: any,
   handleSelectAll,
   handleClearAll,
   sectionNumber: number
};


const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      border: {
         width: '100%',
         backgroundColor: theme.palette.grey[10],
         position: 'relative',
         overflow: 'auto',
         border: '3px solid rgba(0, 0, 0, 0.1)',
         borderRadius: '4px',
         height: '60vh'
      },
      title: {
         margin: theme.spacing(4, 0, 2),
      },
      sub : {
         fontSize: "large",
      }
   }),
);

export default function ScrollableListWithSearch(props: ScrollableListWithSearchProps) {
   const classes = useStyles();
   const [allCheckboxesStates, setAllCheckboxesStates] = React.useState({});
   const [selected, setSelected] = React.useState("");
   const [filteredValues, setFilteredValues] = React.useState(props.options)
   const [search, setSearch] = React.useState("");
   const { t } = useTranslation();
   const regex =  new RegExp("^(.*)([\u2090-\u209C][\u2080-\u2089]+,?[^\u0000-\u00ff]+)+(.*)$");

   useEffect(() => {
      const initState = {};
      props.options.forEach((opt) => {
         initState[opt.name] = opt.checked;
      });

      setAllCheckboxesStates(initState);
      setFilteredValues(props.options)

   }, [setAllCheckboxesStates, props.options]);



   const handleChange = (parent: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onCheck(parent, event.target.name, event.target.checked)
      setAllCheckboxesStates({
         ...allCheckboxesStates,
         [event.target.name]: event.target.checked,
      });
   };

   const onSearchChange = (value: string) => {
      setSearch(value)
      setFilteredValues(props.options.filter((opt) => t(opt.label).toLowerCase().includes(value.toLowerCase())))
   }

   const onSelectOption = (opt: OptionProps) => {
      if (opt.hasChild && !opt.disabled) {
         setSelected(opt.name);
         props.onExpand(opt.parent, opt.name);
      }
   };

   return (
      <div>
         <div>
            <div style={{ width: '100%' }}>

               <Box display="flex" p={1} visibility={props.options.length > 0 ? "visible" : "hidden"} style={{ justifyContent: 'center', flexDirection: "row" }}>
                  <Box p={1} width="100%" alignSelf="flex-end">
                     <TextField
                        value={search}
                        onChange={(e) => { onSearchChange(e.target.value) }}
                        id='value'
                        style={{ whiteSpace: "nowrap", width: "100%" }}
                        InputProps={{
                           startAdornment: <InputAdornment position="start">
                              <SearchIcon />
                           </InputAdornment>,
                           endAdornment: <InputAdornment position="end">
                              <ClearIcon onClick={() => {
                                 setSearch('')
                                 setFilteredValues(props.options)
                              }} />
                           </InputAdornment>,
                        }}
                     />
                  </Box>
                  <Box p={1} flexShrink={1} alignSelf="flex-end">
                     <Button
                        color='primary'
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() => props.handleSelectAll()}
                     >
                        Select all
                     </Button>
                  </Box>
                  <Box p={1} flexShrink={0} alignSelf="flex-end">
                     <Button
                        color='primary'
                        onClick={() => props.handleClearAll()}
                        style={{ whiteSpace: "nowrap" }}
                     >
                        Clear
                     </Button>
                  </Box>
               </Box>
            </div >
            <List className={classes.border}>
               {
                  props.sectionNumber === 0
                     ? <ListItem>
                        <Typography variant="h5" style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                           {t('plpp-data.manageDataColumns.pipe_carbon_steel')}
                        </Typography>
                     </ListItem>
                     : ''
               }

               {

                  filteredValues.map((opt) => {
                     const text = opt.selectedChildCount !== undefined ? t(opt.label) + " (" + opt.selectedChildCount + ")" : t(opt.label);
                     return (
                        <ListItem style={{ backgroundColor: selected === opt.name ? "#E8E8E8" : opt.disabled ? "#C8C8C8" : "#FFFFFF" }}>
                           <ListItemIcon>
                              <Checkbox
                                 edge="start"
                                 name={opt.name}
                                 checked={allCheckboxesStates[opt.name] ? true : false}
                                 disabled={opt.disabled}
                                 style={{
                                    color: selected === opt.name ? "#000000" : opt.disabled ? "#777777" : theme.palette.primary.main
                                 }}
                                 onChange={handleChange(opt.parent)}
                              />

                           </ListItemIcon>

                           <ListItemText disableTypography onClick={() => { onSelectOption(opt) }}
                              primary={
                                 <Typography variant="body1" style={{
                                    color: selected === opt.name ? "#000000" : opt.disabled ? "#777777" : theme.palette.primary.main,
                                    fontWeight: opt.disabled ? 'normal' : 'bold',
                                    fontStyle: opt.disabled ? 'italic' : 'normal',
                                    cursor: opt.hasChild && !opt.disabled ? 'pointer' : ''
                                 }}>
                                    {
                                       regex.test(text) ? 
                                          <div>
                                          {text.match(regex)[1]}
                                          <sub className={classes.sub}>{text.match(regex)[2]}</sub>
                                          {text.match(regex)[3]}
                                          </div>  
                                       : text
                                    }
                                 </Typography>
                              }
                           />

                           {opt.hasChild && !opt.disabled && <ListItemSecondaryAction >
                              <IconButton edge="end" aria-label="comments" onClick={() => { onSelectOption(opt) }}>
                                 <ChevronRight
                                    style={{
                                       color: selected === opt.name ? "#000000" : opt.disabled ? "#777777" : theme.palette.primary.main,
                                    }} />
                              </IconButton>
                           </ListItemSecondaryAction>}
                        </ListItem>
                     );

                  })
               }
            </List>
         </div>
      </div>
   )
}
