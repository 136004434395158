import React, { useContext, useEffect } from 'react';

import { makeStyles, Theme, Container, Grid, Typography, Card, CardActionArea, CardMedia, CardContent } from '@material-ui/core';

import { AppContext } from '../stores/AppStore';
import { UserContext } from '../stores/UserStore'

import OCTGIcon from '../assets/icons/octg.svg';
import PLPPIcon from '../assets/icons/plpp.svg';
import LoadsIcon from '../assets/icons/loads.svg';


import requests from '../api/api';

import { useHistory } from 'react-router-dom';

function fillGSIUsers() {
    const response = requests.fillBBUsersFromGSI();
    console.log(response);
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    titleContainer: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        textAlign: 'center',
    },
    titleHead: {
        color: 'rgb(89, 203, 228)',
        fontSize: 48,
        fontWeight: 700,
        marginRight: 16,
    },
    titleTail: {
        fontSize: 48,
        fontWeight: 100,
        textTransform: 'uppercase',
    },
    card: {
        maxWidth: 345,
    },
    card_action: {
        '&.MuiButtonBase-root.Mui-disabled': {
            backgroundColor: '#dddddd47'
        }
    },
    card_media: {
        height: '80px',
        margin: '40px 10px 10px',
        backgroundSize: 'contain'
    }
}));

const Home = () => {
    const classes = useStyles();
    const { setPageSubtitle } = useContext(AppContext);
    const { currentUser } = useContext(UserContext);

    const history = useHistory();

    useEffect(() => {
        fillGSIUsers();
        setPageSubtitle('');
    }, [setPageSubtitle]);



    const onCardClick = (link) => () => {
        history.push(link);
    };

    return (
        <Container className={classes.root} maxWidth="md">
            <Grid container spacing={3}>
                <Grid item className={classes.titleContainer} xs={12}>
                    <Typography><span className={classes.titleHead}>Hello</span> <span
                        className={classes.titleTail}>{currentUser && currentUser.firstName + ' ' + currentUser.lastName}</span></Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={4} sm={12}>
                    <Card className={classes.card}>
                        <CardActionArea disabled={!currentUser || !currentUser.roles.OCTG_DATA_VIEWER} className={classes.card_action} onClick={onCardClick('/octg/home')}>
                            <CardMedia
                                className={classes.card_media}
                                image={OCTGIcon}
                                title="OCTG"
                            />
                            <CardContent>
                                <Typography align="center" variant="h6" color="textSecondary" component="h2">
                                    OCTG
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item md={4} sm={12}>
                    <Card className={classes.card}>
                        <CardActionArea disabled={!currentUser || !currentUser.roles.PLPP_DATA_VIEWER} className={classes.card_action} onClick={onCardClick('/project-plpp-data')}>
                            <CardMedia
                                className={classes.card_media}
                                image={PLPPIcon}
                                title="PLPP"
                            />
                            <CardContent>
                                <Typography align="center" variant="h6" color="textSecondary" component="h2">
                                    PLPP
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item md={4} sm={12}>
                    <Card className={classes.card}>
                        <CardActionArea disabled={!currentUser} className={classes.card_action} onClick={onCardClick('/load/view')}>
                            <CardMedia
                                className={classes.card_media}
                                image={LoadsIcon}
                                title="LOADS"
                            />
                            <CardContent>
                                <Typography align="center" variant="h6" color="textSecondary" component="h2">
                                    LOADS
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Home;
