import React from 'react';

import { Button as MaterialButton, makeStyles, Theme} from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),
    // color: theme.palette.getContrastText(indigo[100]),
    color: '#FFFFFF'
  }
}));


const Button = (props) => {
  const classes = useStyles();
  
    return (
        <MaterialButton className={classes.root} {...props}>
            {props.children}
        </MaterialButton>
    );
}

export default Button;
