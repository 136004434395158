import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PlppContext } from '../../stores/PlppStore'

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      cardComponent: {
         width: '100%',
         padding: '1%',
         paddingLeft: '2%',
         background: '#C7E1BF',
         borderLeft: '8px solid #1F8600',
         borderRadius: '5px',
         marginRight: '50px',
         display:'flex',
         alignItems:'center',
         fontWeight:'bold'
      },
      openModalButton: {
         marginLeft:'5%',
         color: theme.palette.primary.dark,
         background: 'white',
         boxShadow: '0px 2px 3px #00000029',
      },
      button: {
         margin: '0px 24px',
      },
   })
);


const DataSelectedCard = (props: { isPipeView: boolean, switchToPipeView: () => void, switchToTestView: () => void }) => {
   const classes = useStyles();
   const { plppState, plppDispatch } = useContext(PlppContext);

   const { t } = useTranslation();

   const switchToTestsOrDataTab = () => {
        props.isPipeView ? onClickOnViewTestsFromSelectionButton() : onClickOnViewPipesFromSelectionButton();
   }

    const onClickOnViewTestsFromSelectionButton = () => {
			// construct the filtered map column
			const filteredTestsColumnsMap = plppState.filteredTestsColumnsMap
			filteredTestsColumnsMap.set('carbonSteelHeat.number', {
				values: plppState.selectedPlppData
					.map(plppData => plppData.carbonSteelHeat.number)
					.filter(function (value, index, array) {
						// Remove duplicates
						return array.indexOf(value) === index
					}),
				min: '',
				max: ''
			})

			plppDispatch({
				type: 'set_filtered_columns_map',
				filteredColumnsMap: plppState.filteredColumnsMap,
				filteredTestsColumnsMap: filteredTestsColumnsMap
			})

			plppDispatch({ type: 'switch_to_test_view' })

			// Switch to test view
			props.switchToTestView()
		}
    const onClickOnViewPipesFromSelectionButton = () => {
			// construct the filtered map column
			const filteredColumnsMap = plppState.filteredColumnsMap
			filteredColumnsMap.set('carbonSteelHeat.number', {
				values: plppState.selectedTestsPlppData
					.map(plppData => plppData.carbonSteelHeat.number)
					.filter(function (value, index, array) {
						// Remove duplicates
						return array.indexOf(value) === index
					}),
				min: '',
				max: ''
			})

			plppDispatch({
				type: 'set_filtered_columns_map',
				filteredColumnsMap: filteredColumnsMap,
				filteredTestsColumnsMap: plppState.filteredTestsColumnsMap
			})

			plppDispatch({ type: 'switch_to_pipe_view' })

			// Switch to pipe view
			props.switchToPipeView()
		}
    return (
          <div className={classes.cardComponent} title={props.isPipeView && !plppState.isFinishedLoadingTestsPlppData ? t('data_loading'): ""}>
            <div>
               {props.isPipeView ? "( " + plppState.selectedPlppData.length + " row(s) selected )" : "( " + plppState.selectedTestsPlppData.length + " row(s) selected )"}
            </div>
            <Button
                        id='switchToOtherTab'
                        className={classes.openModalButton}
                        onClick={switchToTestsOrDataTab}
                        disabled={props.isPipeView && !plppState.isFinishedLoadingTestsPlppData}
                     >
                        {props.isPipeView ? t('plpp-data.viewTestsFromSelection') : t('plpp-data.viewPipesFromSelection')}
                     </Button>
          </div>
    )

};


export default DataSelectedCard;


