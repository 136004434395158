import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme, Grid, Typography, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackSharp';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  titleContainer: {
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center'        
  },
  titleHead: {
      color: 'rgb(89, 203, 228)',
      fontSize: 24,
      fontWeight: 700,
      marginRight: 0,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'        
  }
}));

const PageToolbar = (props) => {
  const history = useHistory();
  const classes = useStyles();
  
  const onBackClick = () => {
    history.goBack()
  };

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item className={classes.titleContainer} xs={6}>
          { props.backButton &&
            <IconButton color="primary" component="span" onClick={onBackClick}>
                <ArrowBackIcon fontSize="large"/>
            </IconButton> 
          }                   
          <Typography><span className={classes.titleHead}>{props.title}</span></Typography>
      </Grid>
      <Grid item className={classes.buttonContainer} xs={6}>
          {props.children}        
      </Grid>
    </Grid>
  );
};

export default React.memo(PageToolbar);