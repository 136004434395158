/* eslint-disable react-hooks/exhaustive-deps */
import { AppBar, Badge, fade, Icon, IconButton, InputBase, makeStyles, Menu, MenuItem, Snackbar, Toolbar, Typography } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useContext, useEffect, useState } from 'react';
import { version } from '../../../package.json';
import { useLocation } from 'react-router-dom';
import LogoImg from '../../assets/images/logo-small.svg';
import { AppContext } from '../../stores/AppStore';
import { UserContext } from '../../stores/UserStore';


const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.paper,
    // boxShadow: '0 3px 6px 0 rgb(0 0 0 / 10%)'//'0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 4%), 0px 1px 3px 0px rgb(0 0 0 / 2%)'
  },
  logoButton: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    // flexGrow: 1,
    color: '#5a5a5aa3',
    fontWeight: 'bold'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.15),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  grow: {
    flexGrow: 1,
  },
  section: {
    display: 'flex'
  }
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Header = () => {

  const classes = useStyles();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const { applicationMessage, setApplicationMessage } = useContext(AppContext);
  const { currentUser, signOut, refreshSession } = useContext(UserContext);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    console.log(searchText);
  }, [searchText]);

  useEffect(() => {
    if (applicationMessage && applicationMessage.message && (applicationMessage.message.indexOf('Invalid Security Token') !== -1 || applicationMessage.message.indexOf('Unauthorized') !== -1)) {
      signOut();  
    } else
    if (applicationMessage && applicationMessage.message && applicationMessage.message.indexOf('Token is expired') !== -1) {
        refreshSession();  
    }
  }, [applicationMessage]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    // refreshSession();
    setAnchorEl(null);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setApplicationMessage(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchText(event.target.value);
  };

  return <div>
    <AppBar position="static" classes={{ root: classes.appBar }} color="transparent">
      <Toolbar variant="dense">
        {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon/>
                    </IconButton> */}
        <IconButton className={classes.logoButton}>
          <Icon fontSize={'large'}><img src={LogoImg} height={36} alt="Logo" /></Icon>
        </IconButton>
        <Typography variant="h5" className={classes.title}>
          Smartengo Backbone
        </Typography>
        {location.pathname === '/project-plpp-data' || location.pathname === '/plpp-data'
          ? ''
          : <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleSearchChange}
            />
          </div>
        }
        <div className={classes.grow} />
        <div className={classes.section}>
          <IconButton aria-label="show 0 new notifications" color="primary">
            <Badge badgeContent={1} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            edge="end"
            aria-label="Account of Current User"
            aria-controls="user-account-menu"
            aria-haspopup="true"
            onClick={handleOpenMenu}
            color="primary">
            <AccountCircle />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>

    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="user-account-menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleCloseMenu}
    >
      <MenuItem onClick={handleCloseMenu}>{currentUser && currentUser.firstName + ' ' + currentUser.lastName}</MenuItem>
      <MenuItem>{'v' + version}</MenuItem>
      <MenuItem onClick={signOut}>SignOut</MenuItem>
    </Menu>

    <Snackbar open={applicationMessage ? true : false} autoHideDuration={6000} onClose={handleCloseMessage} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
      {applicationMessage && <Alert onClose={handleCloseMessage} severity={applicationMessage.type}>
        {applicationMessage.message}
      </Alert>}
    </Snackbar>
  </div>
};

export default Header;

