/** @format */

import { Box, createStyles, Icon, makeStyles, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import exam from '../assets/icons/exam.svg'
import pipe from '../assets/icons/pipe.svg'
import Card from '../components/common/Card'
import DataSelectedCard from '../components/common/DataSelectedCard'
import DisplayExportModalButton from '../containers/plpp/export/DisplayExportModalButton'
import DisplayManageColumnsModalButton from '../containers/plpp/manageColumns/DisplayManageColumnsModalButton'
import PlppDataTable from '../containers/plpp/PlppDataTable'
import ProjectAndMaterialSelect from '../containers/plpp/ProjectAndMaterialSelect'
import TestsPlppDataTable from '../containers/plpp/TestsPlppDataTable'
import { PlppContext } from '../stores/PlppStore'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(() =>
	createStyles({
		tab: {
			'&:hover': {
				filter: 'invert(40%) sepia(93%) saturate(1757%) hue-rotate(170deg) brightness(98%) contrast(101%)'
			},
			'&.Mui-selected': {
				filter: 'invert(40%) sepia(93%) saturate(1757%) hue-rotate(170deg) brightness(98%) contrast(101%)'
			}
		}
	})
)

const PlppData = () => {
	const classes = useStyles()
	const [value, setValue] = React.useState('pipes')
	const { plppState, plppDispatch } = useContext(PlppContext)
	const { t } = useTranslation()

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue)
		plppDispatch({ type: 'tab_has_changed', tabToDisplay: newValue })
		plppDispatch({ type: 'set_is_need_to_refresh_plpp_columns', isNeedToRefreshPlppColumns: true })
	}

	const switchToPipeView = () => {
		handleChange(null, 'pipes')
	}
	const switchToTestView = () => {
		handleChange(null, 'tests')
	}

	const isDisplayed = () => {
		return (
			(plppState.selectedPlppData.length > 0 && value === 'pipes') ||
			(plppState.selectedTestsPlppData.length > 0 && value === 'tests')
		)
	}
	let testViewIcon
	if (plppState.isFinishedLoadingTestsPlppData) {
		testViewIcon = (
			<Icon fontSize={'large'}>
				{' '}
				<img src={exam} height={36} alt="Logo" />{' '}
			</Icon>
		)
	} else {
		testViewIcon = (
			<div title={plppState.testsDataToDisplayHaveNotBeenLoadedYet ? t('data_loading') : ''}>
				<CircularProgress color="inherit" />
			</div>
		)
	}
	return (
		<Box width="100%" height="100%">
			<Box display="flex" justifyContent="flex-end">
				<ProjectAndMaterialSelect isPipeView={value === 'pipes'} />
			</Box>
			<Card padding="16px" margin="16px 16px">
				<TabContext value={value}>
					<Box display="flex" style={{ width: '100%' }}>
						<Box style={{ flexGrow: 1 }}>
							<TabList onChange={handleChange} indicatorColor="primary">
								<Tab
									className={classes.tab}
									icon={
										<Icon fontSize={'large'}>
											<img src={pipe} height={36} alt="Logo" />
										</Icon>
									}
									label={t('plpp-data.pipesViewingMode')}
									value="pipes"
								/>
								<Tab
									style={{ pointerEvents: 'auto' }}
									className={!plppState.testsDataToDisplayHaveNotBeenLoadedYet && classes.tab}
									icon={testViewIcon}
									label={
										<span title={plppState.testsDataToDisplayHaveNotBeenLoadedYet ? t('data_loading') : ''}>
											{t('plpp-data.testsViewingMode')}
										</span>
									}
									value="tests"
									disabled={plppState.testsDataToDisplayHaveNotBeenLoadedYet}
								/>
							</TabList>
						</Box>
						{isDisplayed() && (
							<Box display="flex" flexGrow="2" width="50%">
								<DataSelectedCard
									isPipeView={value === 'pipes'}
									switchToTestView={switchToTestView}
									switchToPipeView={switchToPipeView}
								/>
							</Box>
						)}
						<Box display="flex" style={{ alignItems: 'center' }} gridColumnGap="20px">
							<DisplayManageColumnsModalButton isPipeView={value === 'pipes'} />
							{value === 'pipes' && <DisplayExportModalButton />}
						</Box>
					</Box>

					<TabPanel value="pipes" style={{ width: '100%' }}>
						<PlppDataTable switchToTestView={switchToTestView} />
					</TabPanel>
					<TabPanel value="tests" style={{ width: '100%' }}>
						<TestsPlppDataTable switchToPipeView={switchToPipeView} />
					</TabPanel>
				</TabContext>
			</Card>
		</Box>
	)
}

export default PlppData
