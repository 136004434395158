/** @format */

import axios from 'axios'
import Cookies from 'js-cookie'

// import actualData from './mock/actual-data.json';
// import plants from './mock/plants.json';
// import clients from './mock/clients.json';
// import certificates from './mock/uploaded-certificates.json';

const TOKEN_COOKIE = '_ssoToken'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
	headers: {
		'Content-Type': 'application/json',
		'VLR-Authorization': btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))
	}
})

instance.interceptors.request.use(
	request => {
		const idToken = Cookies.get(TOKEN_COOKIE)
		if (!idToken) {
			request.cancelToken = new axios.CancelToken(cancel => cancel('Token is expired'))
		} else {
			request.headers['Authorization'] = idToken
		}
		return request
	},
	error => {
		return Promise.reject(error)
	}
)

const isSuccessfulResponse = response => response && response.status === 200

const manageResponse = response => {
	if (isSuccessfulResponse(response)) {
		const result = response.data.body ? response?.data?.body : response.data
		if (typeof result == 'string' && !result.startsWith('http')) {
			try {
				return JSON.parse(result)
			} catch (e) {
				return result
			}
		}
		return result
	} else {
		throw new Error("Couldn't retrieve data")
	}
}

const manageError = error => {
	let message = JSON.stringify(error)
	if (error.response) {
		if (error.response.data) {
			try {
				const data = JSON.parse(error.response.data)
				if (data.message) {
					message = data.message
				} else if (data.errorMessage) {
					message = data.errorMessage
				}
			} catch (tryError) {
				message = error.response.data.message
					? error.response.data.message
					: error.response.data.errorMessage
						? error.response.data.errorMessage
						: error.response.data
			}
		} else {
			try {
				const data = JSON.parse(error.response)
				if (data.message) {
					message = data.message
				} else if (data.errorMessage) {
					message = data.errorMessage
				}
			} catch (tryError) {
				message = error.response.message
					? error.response.message
					: error.response.errorMessage
						? error.response.errorMessage
						: error.response
			}
		}
	} else {
		try {
			const data = JSON.parse(error)
			if (data.message) {
				message = data.message
			} else if (data.errorMessage) {
				message = data.errorMessage
			}
		} catch (tryError) {
			message = error.message ? error.message : error.errorMessage ? error.errorMessage : message
		}
	}

	if (message.indexOf('Invalid Security Token') !== -1) {
		message = 'Invalid Security Token'
	} else if (message.indexOf('Unauthorized') !== -1) {
		message = 'Unauthorized'
	} else if (message.indexOf('Token is expired') !== -1) {
		message = 'Token is expired'
	}

	return message
}

const requests = {
	uploadNominalData: async params => {
		try {
			const response = await instance.post('/loads/upload/nominal', params)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(manageError(error))
		}
	},
	uploadActualData: async params => {
		try {
			const response = await instance.post('/loads/upload/actual', params)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(manageError(error))
		}
	},
	uploadCertificate: async params => {
		try {
			const response = await instance.post('/loads/upload/certificate', params)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},
	updateCertificate: async params => {
		try {
			const response = await instance.post('/loads/update/certificate', params)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},
	downloadCertificate: async productId => {
		try {
			const url = '/loads/' + productId + '/certificate'
			const response = await instance.get(url)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},
	validateData: async params => {
		try {
			const response = await instance.post('/loads/validate', params)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw error
		}
	},
	getPlants: async () => {
		try {
			// return plants;
			const response = await instance.get('/plants')
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(manageError(error))
		}
	},
	getLoads: async () => {
		try {
			// return plants;
			const response = await instance.get('/loads')
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(manageError(error))
		}
	},
	getClients: async () => {
		try {
			// return clients;
			const response = await instance.get('/clients')
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(manageError(error))
		}
	},
	getOctgActualData: async () => {
		try {
			// return actualData;
			const response = await instance.get('/products')
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(manageError(error))
		}
	},
	getOctgActualPendingData: async (id?: number) => {
		try {
			// return actualData;
			const url = id ? '/products/pending/' + id : '/products/pending'
			const response = await instance.get(url)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(manageError(error))
		}
	},
	getOctgActualValidatedData: async () => {
		try {
			// return actualData;
			const response = await instance.get('/products/validated')
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(manageError(error))
		}
	},
	getOctgNominalData: async () => {
		try {
			// return actualData;
			const response = await instance.get('/items')
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(manageError(error))
		}
	},
	getOctgNominalPendingData: async (id?: number) => {
		try {
			// return actualData;
			const url = id ? '/items/pending/' + id : '/items/pending'
			const response = await instance.get(url)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(manageError(error))
		}
	},
	getOctgNominalValidatedData: async () => {
		try {
			// return actualData;
			const response = await instance.get('/items/validated')
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(manageError(error))
		}
	},
	getUploadedCertificates: async () => {
		try {
			// return certificates;
			const response = await instance.get('/loads/uploaded-certificates')
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(manageError(error))
		}
	},
	getOrderData: async (cognitoId: string) => {
		try {
			const response = await instance.get(`/carbon-steel-order?userCognitoId=${cognitoId}`)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},
	getPlppData: async (itemIds?: string[]) => {
		try {
			const response = await instance.get(
				`/carbon-steel-product-plpp?${itemIds ? 'items=' + itemIds.join(',') : ''}`
			)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},

	getPlppDataOffset: async (offset: number, limit: number, itemIds?: string[]) => {
		try {
			const response = await instance.get(
				`/carbon-steel-product-plpp?offset=${offset}&limit=${limit}${itemIds ? '&items=' + itemIds.join(',') : ''}`
			)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},
	getTestsPlppDataOffset: async (offset: number, limit: number, itemIds?: string[]) => {
		try {
			const response = await instance.get(
				`/carbon-steel-heat?offset=${offset}&limit=${limit}${itemIds ? '&items=' + itemIds.join(',') : ''}`
			)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},
	getCertificatePlppData: async (valid: string) => {
		try {
			const response = await instance.get(`/carbon-steel-product-plpp/${valid}/certificate`)
			console.log(response)

			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},
	getCertificatePlppDataByTally: async (tallyNumber: string, orderNumber: string, itemNumber: string) => {
		try {
			const response = await instance.get(
				`/carbon-steel-certificate?tally=${tallyNumber}&order=${orderNumber}&item=${itemNumber}`
			)
			console.log(response)

			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},
	launchPDEFTask: async (pdefParam: {
		isForAll: boolean
		orderItems: Array<{ order: string; item: string }>
		tallies: Array<{ order: string; item: string; tally: string }>
	}) => {
		try {
			const response = await instance.post(`/carbon-steel-product-plpp/export/pdef`, pdefParam)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},
	getPDEFLink: async (fileName: string) => {
		try {
			const response = await instance.get(`/carbon-steel-product-plpp/export/pdef?uuid=${fileName}`)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},
	getAllTemplatesByUserData: async (userCognitoId: string) => {
		try {
			const response = await instance.get(`/carbon-steel-template?userId=${userCognitoId}`)
			console.log(response)

			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},
	createTemplate: async (template: any) => {
		try {
			const response = await instance.post(`/carbon-steel-template`, template)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},
	updateTemplate: async (template: any) => {
		try {
			const response = await instance.patch(`/carbon-steel-template`, template)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},
	deleteTemplate: async (templateId: string) => {
		try {
			const response = await instance.delete(`/carbon-steel-template/${templateId}`)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	},
	fillBBUsersFromGSI: async () => {
		try {
			const response = await instance.post(`/run-cognito-user`)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(error)
		}
	}
}
export default requests
