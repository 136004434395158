import React, {ReactNode} from 'react';

import FlexView, {FlexViewType} from './FlexView';

/**
 * Card component that extends FlexView
 */
const Card = React.forwardRef<any, FlexViewType & { children: ReactNode }>((props, ref) => <FlexView
    ref={ref} {...props} />);

Card.defaultProps = {
    backgroundColor: 'white',
    borderRadius: 'card',
    padding: '16px 24px',
    margin: '8px',
    elevation: 'low'
};

export default Card;
