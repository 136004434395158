/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState, useMemo } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { makeStyles, 
    Theme, 
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Dialog, 
    DialogContent, 
    DialogTitle,
    IconButton } from '@material-ui/core';

import { AppContext } from '../stores/AppStore';
import { UserContext } from '../stores/UserStore';
import { LoadContext } from '../stores/LoadStore';

import requests from '../api/api';

import PageContainer from '../components/layout/PageContainer';
import PageBody from '../components/layout/PageBody';
import LoadingOverlay from '../components/common/LoadingOverlay';

import DataTable from '../components/table/DataTable';

import ListIcon from '@material-ui/icons/List';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowBackIcon from '@material-ui/icons/ArrowBackSharp';
import ArrowForwardSharp from '@material-ui/icons/ArrowForwardSharp';


import { purple } from '@material-ui/core/colors';


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    titleHead: {
        color: 'rgb(89, 203, 228)',
        fontSize: 24,
        fontWeight: 700,
        marginRight: 0,
    },
    dialogTitle: {
        padding: '10px 24px 0px 24px'
    },
    dialogContent: {
        minHeight: '50vh'
    },
    textField: {
        width:  'calc(100% - 20px)',
        margin: theme.spacing(1)
    },
    row: {
        display: 'flex', 
        flexDirection: 'row'
    },
    detailGrid: {
        width:  window.innerWidth,
        margin: '0 20px',
        fontSize: 13
    },
    buttonPurple: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500],
        '&:hover': {
            backgroundColor: purple[700],
        },
    },
    buttonValidate: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 11,
        textTransform: 'uppercase',
        backgroundColor: purple[500],
        color: theme.palette.getContrastText(purple[500]),
        borderRadius: 5,
        paddingLeft: 8,
        paddingRight: 8
    },
    list: {
        width: 'calc(100% - 20px)',
        backgroundColor: theme.palette.background.paper,
    },         
    listItem: {
        margin: '0px',
        padding: '0px'
    },
    checkbox: {
        margin: '0px',
        padding: '0px'
    },
    listItemIcon: {
        minWidth: '32px'        
    },
    listItemText: {
        margin: '0px',
        padding: '0px',
        fontSize: '13px'
    },
    card: {
        minHeight: '100%',
        marginRight: '10px'
    },
    validationErrorText: {
        marginTop: 0, 
        color: theme.palette.error.main        
    }
}));


const ViewLoadData = () => {
    const history = useHistory();
    const classes = useStyles();
    const { showErrorMessage } = useContext(AppContext);
    const { currentUser } = useContext(UserContext);

    const { loadState, loadDispatch } = useContext(LoadContext)
    const [isLoading, setLoading] = useState(true);

    const [defaultColumns, setDefaultColumns] = useState([]);

    const [showSelectColumnDialog, setShowSelectColumnDialog] = useState(false);    
    
    
    // const handleClickBack = () => {
    //     history.goBack()
    // };       

    const handleClickShowSelectColumnDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
        setShowSelectColumnDialog(true);
    };    

    const handleClickCloseSelectColumnDialog = () => {
        setShowSelectColumnDialog(false);
    };

    const handleCheckColumn = (rowData: any) => () => {
        const list = JSON.parse(JSON.stringify(defaultColumns));
        const column = list.find(x => x.field === rowData.field);
        column.checked = column.checked ? !column.checked : true;
        setDefaultColumns(list);
    };  

    const handleClickForward = (event: React.MouseEvent<HTMLButtonElement>, rowData) => {
        history.push('/octg/view/'+rowData.type.toLowerCase()+'-data/'+rowData.id);
    };

    useEffect(() =>  {
        init();
    }, []);

    useEffect(() =>  {
        const showedDetails = [];
        const showedColumns = [];
        const desfaultCellStyle = {whiteSpace: 'nowrap', fontSize: '13px', margin: '0px', padding: '0px 15px'};
        for (const column of defaultColumns) {
            if (column.checked) {
                if (column.folded) {
                    showedDetails.push({ title: column.title, field: column.field});
                } else {
                    showedColumns.push({ title: column.title, field: column.field, cellStyle: desfaultCellStyle, render: column.render });
                }
            }
        }
        loadDispatch({ type: 'set_load_columns', loadColumns: showedColumns });
        loadDispatch({ type: 'set_load_details', loadDetails: showedDetails });
    }, [defaultColumns]);

    const init = () => {
        const defaultColumns = [     
            { title: 'Id', field: 'id', checked: true, folded: false },
            { title: 'Type', field: 'type', checked: true, folded: false, render: rowData => rowData.type === 'nominal' ? 'Nominal' : 'Actual' },
            { title: 'Title', field: 'title', checked: true, folded: false },
            { title: 'Loads Status', field: 'status', checked: true, folded: false },
            { title: 'Client', field: 'clientName', checked: true, folded: false },
            { title: 'Load Date', field: 'creationDate', checked: true, folded: false, render: rowData => rowData.creationDate ? format(new Date(rowData.creationDate), 'MMM dd, yyyy, hh:mm:ss, aa') : '' },
            { title: 'Validation Date', field: 'modificationDate', checked: true, folded: false, render: rowData => rowData.modificationDate ? format(new Date(rowData.modificationDate), 'MMM dd, yyyy, hh:mm:ss, aa') : '' },
            { title: 'Remarks', field: 'comment', checked: true, folded: false },
            { title: 'Total Created', field: 'totalCreated', checked: true, folded: false },
            { title: 'Total Uploaded', field: 'totalUploaded', checked: true, folded: false },
        ];

        setDefaultColumns(defaultColumns);

        loadTableData();

    }      
   
    const loadTableData = () => {
        if (currentUser) {
            setLoading(true);
            requests.getLoads().then((data) => {
                loadDispatch({ type: 'set_load_data', loadData: data });
            }).catch(error => {
                showErrorMessage(error.message);
            }).finally(() => {
                setLoading(false);            
            });                        
        } else {
            loadDispatch({ type: 'set_load_data', loadData: [] });
            setLoading(false);            
            showErrorMessage('The user doesn´t have permissions to see this informations!');
        }
    }

    const renderTableData = useMemo(() => <DataTable
        title="View Loads Data"
        style={{color: 'rgb(89, 203, 228)'}}
        filtering={true}
        columns={loadState.loadColumns}
        data={loadState.loadData}
        actions={[
            // { icon: () => <ArrowBackIcon color="primary" />, tooltip: 'Go Back', onClick: (event) => handleClickBack(), isFreeAction: true },
            { icon: () => <RefreshIcon color="primary" />, tooltip: 'Refresh', onClick: (event) => loadTableData(), isFreeAction: true },
            { icon: () => <ListIcon color="primary" />, tooltip: 'Select Columns', onClick: handleClickShowSelectColumnDialog, isFreeAction: true },
            rowData => ({
                icon: () => <ArrowForwardSharp color="secondary" />, 
                tooltip: 'Details',
                // disabled: !rowData.certificateNumber,
                onClick: handleClickForward
            }),

        ]}
        // detailPanel={rowData => {
        //     return ( <Grid container className={classes.detailGrid} spacing={1}>
        //         { loadState.loadDetails.map((column) => {
        //             return (
        //                 <Grid key={column.field} item xs={4}>
        //                     <b>{column.title}:</b> {rowData[column.field]}
        //                 </Grid>
        //             );
        //         })}        
        //     </Grid> )
        // }}                    
    />, [loadState.loadColumns, loadState.loadData]);

    
    const renderListSelectColumns = useMemo(() => <List className={classes.list} dense component="div" role="list" >
        {defaultColumns.map((column) => {
            const labelId = `column-list-label-${column.field}`;
            return (
                <ListItem key={column.field} className={classes.listItem} role={undefined} dense button onClick={handleCheckColumn(column)}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <Checkbox
                            className={classes.checkbox}
                            edge="start"
                            checked={column.checked ? column.checked : false}
                            tabIndex={-1}
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                    </ListItemIcon>
                    <ListItemText id={labelId} className={classes.listItemText} primary={column.title} />
                </ListItem>
            );
        })}
    </List>, [defaultColumns, showSelectColumnDialog]);

    return (
        <PageContainer className={classes.root}>
            <PageBody>
                {renderTableData}           
            </PageBody>

            <Dialog
                disableBackdropClick
                disableEscapeKeyDown            
                fullWidth
                maxWidth="sm"
                open={showSelectColumnDialog}
                aria-labelledby="column-selected-dialog">

                <DialogTitle className={classes.dialogTitle} id="column-selected-dialog">
                    <IconButton color="primary" component="span" onClick={handleClickCloseSelectColumnDialog}>
                        <ArrowBackIcon />
                    </IconButton> 
                    Select Columns
                </DialogTitle>

                <DialogContent dividers={false} className={classes.dialogContent}>
                    {renderListSelectColumns}
                </DialogContent>
            </Dialog>

            <LoadingOverlay open={isLoading} />
        </PageContainer>
    );
};

export default ViewLoadData;
