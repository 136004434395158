import React from 'react';

import {CssBaseline} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/core/styles';

import { toast, Zoom } from 'react-toastify';
import './i18n'

import {AppProvider} from './stores/AppStore';
import { PlppProvider } from './stores/PlppStore';
import { UserProvider } from './stores/UserStore';
import { OctgProvider } from './stores/OctgStore';
import { LoadProvider } from './stores/LoadStore';

import Router from './Router';
import smartengoTheme from './utils/theme';

toast.configure({
    autoClose: 4000,
    draggable: false,
    transition: Zoom,
    hideProgressBar: true,
    closeButton: true,
  });

const App = () => {
    return (
        <ThemeProvider theme={smartengoTheme}>
            <CssBaseline/>
            <AppProvider>
                <UserProvider>
                    <LoadProvider>
                        <PlppProvider>
                            <OctgProvider>
                                <Router />
                            </OctgProvider>
                        </PlppProvider>
                    </LoadProvider>
                </UserProvider>
            </AppProvider>
        </ThemeProvider>
    );
};

export default App;
