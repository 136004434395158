import styled from 'styled-components';

import {
    IWithBackgroundColor,
    IWithBackgroundGradient,
    IWithBorderRadius,
    IWithColor,
    IWithElevation,
    IWithFlex,
    IWithFontWeight,
    IWithFontSize,
    IWithHeight,
    IWithMargin,
    IWithPadding,
    IWithPosition,
    IWithWidth,
} from '../../utils/styled-decorators';

import {
    withBackgroundColor,
    withBackgroundGradient,
    withBorderRadius,
    withColor,
    withElevation,
    withFlex,
    withFontWeight,
    withFontSize,
    withHeight,
    withMargin,
    withPadding,
    withPosition,
    withWidth
} from '../../utils/styled-decorators';

type FlexViewIntrinsicProps = Partial<{
}>;

type FlexViewType = Partial<
    IWithBackgroundColor
    & IWithBackgroundGradient
    & IWithBorderRadius
    & IWithColor
    & IWithElevation
    & IWithFlex
    & IWithFontSize
    & IWithFontWeight
    & IWithHeight
    & IWithMargin
    & IWithPadding
    & IWithPosition
    & IWithWidth
    & FlexViewIntrinsicProps>;

export type {FlexViewType};

const FlexView = styled.div<FlexViewType>`
  ${withBackgroundColor()}
  ${withBackgroundGradient()}
  ${withBorderRadius()}
  ${withColor()}
  ${withElevation()}
  ${withFlex()}
  ${withFontSize()}
  ${withFontWeight()}
  ${withHeight()}
  ${withMargin()}
  ${withPadding()}
  ${withPosition()}
  ${withWidth()}
`;

export default FlexView;
