import React, { useState, useCallback } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const Any: any = {};

//@ts-ignore
const initialState: any = {
    name: 'Smartengo Backbone',
    subtitle: '',
    version: process.env.REACT_APP_VERSION,
    isHeaderVisible: true,
    pageTitle: 'Smartengo Backbone',
    pageSubtitle: '',
    setRedirectUrl: () => {},
    redirectUrl: '',
    lengthUnit: '',
    weightUnit: '',
    convertToCurrentLengthUnit: (value_m: any, value_ft: any, unit: any) => Any,
    convertToCurrentWeightUnit: (value: any, unit: any) => Any,
    setLengthUnit: () => {},
    setWeightUnit: () => {},
    setPageTitle: () => {},
    setPageSubtitle: (subtitle: string) => {},
    setHeaderVisibility: () => {},
    formatNumberByCulture: (value: any, digits: any) => '',
    pageLoading: true,
    setPageLoading: (pageLoading: boolean) => {},
    options: []
}

const nullValue = value => value === null || value === undefined || value === ''

export const AppContext = React.createContext(initialState)

export const AppProvider = ({ children }) => {
    const [redirectUrl, setRedirectUrl] = useState('')
    const [pageTitle, setPageTitle] = useState(initialState.name)
    const [pageSubtitle, setPageSubtitle] = useState(initialState.subtitle)
    const [isHeaderVisible, setHeaderVisibility] = useState(true)
    const [lengthUnit, setLengthUnit] = useState('m')
    const [weightUnit, setWeightUnit] = useState('kg')
    const { i18n } = useTranslation()
    const [ pageLoading, setPageLoading ] = useState(false)
    const { t } = useTranslation()
    const [applicationMessage, setApplicationMessage] = useState(null);    


    moment.locale(i18n.language)

    const options = [
        {
            title: t('UPLOAD PIPE DATA'),
            key: 'UPLOAD_PIPE_DATA',
            route: '/upload',
            baseUrl: 'upload',
            endpoints: [
                'nominal-data',
                'actual-data',
                'certificates'
            ]
        },
        {
            title: t('PIPE DATA LOADS'),
            key: 'PIPE_DATA_LOADS',
            route: '/loads',
            baseUrl: 'loads',
            endpoints: [
                'load-details'
            ]
        },
        {
            title: t('VIEW PIPE DATA'),
            key: 'VIEW_PIPE_DATA',
            route: '/nominal-data',
            baseUrl: 'view',
            endpoints: [
                'nominal-data',
                'actual-data'
            ]
        }
    ]

    const formatNumberByCulture = useCallback((value, digits) => {
        return new Intl.NumberFormat(i18n.language, { minimumFractionDigits: digits } ).format( value )
    }, [i18n.language])

    const convertToCurrentLengthUnit = useCallback((value_m,value_ft, unit) => {
        const nullMetricValue = nullValue(value_m)
        const nullImperialValue = nullValue(value_ft)
        const selectedValueNull = ((nullMetricValue && lengthUnit === 'm') || (nullImperialValue && lengthUnit === 'ft'))
        if (nullMetricValue && nullImperialValue) {
            return 0
        }

        if (selectedValueNull) {
            return 0
        }

        if (unit === 'm') {
            return formatNumberByCulture(value_m, 3)
        }

        if (lengthUnit === 'ft') {
            return formatNumberByCulture(value_ft, 3)
        }
        return value_m
    }, [lengthUnit, formatNumberByCulture])

    const convertToCurrentWeightUnit = useCallback((value, unit) => {
        if (value === null || value === undefined || value === '') {
            return 0
        }
        if (unit === weightUnit) {
            return formatNumberByCulture(value, 3)
        }
        else if (weightUnit === 'lb') {
            return formatNumberByCulture(value*2.20462, 3)
        }
        return formatNumberByCulture(value/2.20462, 3)
    }, [weightUnit, formatNumberByCulture])


    const showSuccessMessage = (message) => {
        setApplicationMessage({type: 'success', message: message})
    };

    const showErrorMessage = (message) => {
        setApplicationMessage({type: 'error', message: message})
    };

    const showWarningMessage = (message) => {
        setApplicationMessage({type: 'warning', message: message})
    };

    const showInfoMessage = (message) => {
        setApplicationMessage({type: 'info', message: message})
    };
    

    //@ts-ignore
    return <AppContext.Provider value={{
        ...initialState,
        redirectUrl,
        pageTitle,
        pageSubtitle,
        isHeaderVisible,
        lengthUnit,
        weightUnit,
        convertToCurrentLengthUnit,
        convertToCurrentWeightUnit,
        //@ts-ignore
        setLengthUnit,
        //@ts-ignore
        setWeightUnit,
        //@ts-ignore
        setRedirectUrl,
        //@ts-ignore
        setPageTitle,
        //@ts-ignore
        setPageSubtitle,
        //@ts-ignore
        setHeaderVisibility,
        formatNumberByCulture,
        pageLoading,
        //@ts-ignore
        setPageLoading,
        options,
        applicationMessage, 
        setApplicationMessage,
        showSuccessMessage,
        showErrorMessage,
        showWarningMessage,
        showInfoMessage
    }}>
        {children}
    </AppContext.Provider>
}
