/** @format */

import { createStyles, Icon, makeStyles, Theme } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import { MTableHeader } from 'material-table'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import expandLeft from '../../assets/icons/expand_left.svg'
import expandRight from '../../assets/icons/expand_right.svg'
import FilterableTable from '../../components/common/FilterableTable'
import LoadingOverlay from '../../components/common/LoadingOverlay'
import { AppContext } from '../../stores/AppStore'
import { PlppContext } from '../../stores/PlppStore'
import { UserContext } from '../../stores/UserStore'
import ColumnsOrdering from './manageColumns/ColumnsOrdering'
import PopoverFilter from './PopoverFilter'
import ChipsFilters from '../../components/common/ChipsFilters'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		chemicalAnalysisHeader: {
			padding: '4px',
			fontSize: '1rem',
			borderTop: 'solid 1px lightgrey',
			borderLeft: 'solid 1px lightgrey',
			borderRight: 'solid 1px lightgrey',
			minWidth: '400px'
		},
		backgroundExtended: {
			background: '#F2FAFD',
			backgroundClip: 'padding-box'
		},
		noBackground: {
			background: '#FFFFFF',
			backgroundClip: 'padding-box'
		},
		vertical_align: {
			verticalAlign: 'middle'
		},
		expand_btn: {
			cursor: 'pointer',
			float: 'right'
		},
		collapse_btn: {
			cursor: 'pointer',
			float: 'left'
		},
		grey: {
			filter: 'invert(67%) sepia(57%) saturate(0%) hue-rotate(189deg) brightness(103%) contrast(109%)'
		},
		green: {
			filter: 'invert(53%) sepia(19%) saturate(1266%) hue-rotate(100deg) brightness(96%) contrast(87%)'
		},
		red: {
			filter: 'invert(41%) sepia(62%) saturate(804%) hue-rotate(316deg) brightness(96%) contrast(97%)'
		}
	})
)

const TestsPlppDataTable = (props: { switchToPipeView: () => void }) => {
	const classes = useStyles()
	const { pageLoading, setPageLoading } = useContext(AppContext)
	const { plppState, plppDispatch } = useContext(PlppContext)
	const { currentUser, setAuthState } = useContext(UserContext)
	const { t } = useTranslation()
	const [selectedItemIds] = useState<string[]>(plppState.selectedItemIds)
	const [isChemicalGroupExpanded, setIsChemicalGroupExpanded] = useState<boolean>(false)
	const [chemicalAnalysisColumns, setChemicalAnalysisColumns] = useState<string[]>([])
	const [isTensileTestGroupExpanded, setIsTensileTestGroupExpanded] = useState<boolean>(false)
	const [tensileTestColumns, setTensileTestColumns] = useState<string[]>([])
	const [isImpactTestGroupExpanded, setIsImpactTestGroupExpanded] = useState<boolean>(false)
	const [impactTestColumns, setImpactTestColumns] = useState<string[]>([])
	const [isHardnessTestGroupExpanded, setIsHardnessTestGroupExpanded] = useState<boolean>(false)
	const [hardnessTestColumns, setHardnessTestColumns] = useState<string[]>([])
	const retrieveAllChemicalAnalysisColumns = useCallback(() => {
		return plppState.plppColumns
			.filter(column => column.visible && column.isForTestView)
			.filter(column => column.chemicalAnalysisGroup)
			.map(key => key.field)
	}, [plppState.plppColumns])

	const retrieveChemicalAnalysisColumns = useCallback(() => {
		let allChemicalAnalysisColumns = retrieveAllChemicalAnalysisColumns()
		let chemicalAnalysisFinal =
			!isChemicalGroupExpanded && allChemicalAnalysisColumns.length > 3
				? allChemicalAnalysisColumns.slice(0, 3)
				: allChemicalAnalysisColumns
		setChemicalAnalysisColumns(chemicalAnalysisFinal)
	}, [retrieveAllChemicalAnalysisColumns, isChemicalGroupExpanded])

	const retrieveAllTensileTestColumns = useCallback(() => {
		return plppState.plppColumns
			.filter(column => column.visible && column.isForTestView)
			.filter(column => column.tensileTestGroup)
			.map(key => key.field)
	}, [plppState.plppColumns])

	const retrieveTensileTestColumns = useCallback(() => {
		let allTensileTestColumns = retrieveAllTensileTestColumns()
		let tensileTestFinal =
			!isTensileTestGroupExpanded && allTensileTestColumns.length > 3
				? allTensileTestColumns.slice(0, 3)
				: allTensileTestColumns
		setTensileTestColumns(tensileTestFinal)
	}, [retrieveAllTensileTestColumns, isTensileTestGroupExpanded])

	const retrieveAllImpactTestColumns = useCallback(() => {
		return plppState.plppColumns
			.filter(column => column.visible && column.isForTestView)
			.filter(column => column.impactTestGroup)
			.map(key => key.field)
	}, [plppState.plppColumns])

	const retrieveImpactTestColumns = useCallback(() => {
		let allImpactTestColumns = retrieveAllImpactTestColumns()
		let impactTestFinal =
			!isImpactTestGroupExpanded && allImpactTestColumns.length > 3
				? allImpactTestColumns.slice(0, 3)
				: allImpactTestColumns
		setImpactTestColumns(impactTestFinal)
	}, [retrieveAllImpactTestColumns, isImpactTestGroupExpanded])

	const retrieveAllHardnessTestColumns = useCallback(() => {
		return plppState.plppColumns
			.filter(column => column.visible && column.isForTestView)
			.filter(column => column.hardnessTestGroup)
			.map(key => key.field)
	}, [plppState.plppColumns])

	const retrieveHardnessTestColumns = useCallback(() => {
		let allHardnessTestColumns = retrieveAllHardnessTestColumns()
		let hardnessTestFinal =
			!isHardnessTestGroupExpanded && allHardnessTestColumns.length > 3
				? allHardnessTestColumns.slice(0, 3)
				: allHardnessTestColumns
		setHardnessTestColumns(hardnessTestFinal)
	}, [retrieveAllHardnessTestColumns, isHardnessTestGroupExpanded])

	useEffect(() => {
		const updatePlppColumns = () => {
			const defaultVisibleColumns = plppState.selectedPlppColumns

			const allKeys = ColumnsOrdering.sections.flatMap(section => {
				return section.keys
					.flatMap(key => {
						if (key.keys && key.keys.length > 0) {
							return key.keys
						} else {
							return key
						}
					})
					.filter(key => key.hasSpecificDisplay === false)
			})

			const plppColumns: any[] = allKeys.map(key => {
				return {
					title: t('plpp-data.' + key.label + '.name'),
					field: key.label,
					key: key.label,
					defaultSort: key.defaultSort,
					isForPipeView: key.isForPipeView,
					isForTestView: key.isForTestView,
					isMandatoryPipeView: key.isMandatoryPipeView,
					isMandatoryTestView: key.isMandatoryTestView,
					chemicalAnalysisGroup: key.chemicalAnalysisGroup,
					tensileTestGroup: key.tensileTestGroup,
					impactTestGroup: key.impactTestGroup,
					hardnessTestGroup: key.hardnessTestGroup,
					visible: defaultVisibleColumns.includes(key.label) || key.isMandatoryTestView,
					filterComponent: props => (
						<PopoverFilter
							{...props}
							isPipeView={false}
							isAlphanumeric={t('plpp-data.' + key.label + '.filter') === 'alphanumeric'}
							onFilterChanged={onFilterChange(key.label)}
							columnTitle={key.label} />
					),
				}
			})

			plppDispatch({
				type: 'set_plpp_columns',
				plppColumns
			})
		}
		const onFilterChange = (key: string) => {
			return (columnId: any, paramFilter: any) => {
				const min = paramFilter.min ? paramFilter.min : ''
				const max = paramFilter.max ? paramFilter.max : ''
				const splitValues = paramFilter.value ? paramFilter.value.split('|') : ''

				if (paramFilter.clear || ( min === '' && max === '' && splitValues === '' )) {
					plppDispatch({
						type: 'remove_filter_from_columns_map',
						filterToRemoveFromPipeView: undefined,
						filterToRemoveFromTestView: key
					})
				} else {
					// construct the filtered map column
					let filteredTestsColumnsMap = new Map()
					filteredTestsColumnsMap.set(key, {
						values: splitValues,
						min: min,
						max: max
					})

					plppDispatch({
						type: 'set_filtered_columns_map',
						filteredColumnsMap: new Map(),
						filteredTestsColumnsMap
					})
				}
			}
		}

		if (plppState.isNeedToRefreshPlppColumns) {
			setPageLoading(true)
			if (plppState.isFinishedLoadingTestsPlppData) {
				setPageLoading(true)
				plppDispatch({ type: 'set_is_need_to_refresh_plpp_columns', isNeedToRefreshPlppColumns: false })
				updatePlppColumns()
				setPageLoading(false)
			}
		}
		retrieveAllChemicalAnalysisColumns()
	}, [
		plppDispatch,
		retrieveChemicalAnalysisColumns,
		retrieveTensileTestColumns,
		retrieveImpactTestColumns,
		retrieveHardnessTestColumns,
		plppState.selectedPlppColumns,
		plppState.hasClickOnViewTestsFromSelection,
		plppState.isNeedToRefreshPlppColumns,
		selectedItemIds,
		setPageLoading,
		currentUser,
		setAuthState,
		t
	])

	useEffect(() => {
		retrieveChemicalAnalysisColumns()
		retrieveTensileTestColumns()
		retrieveImpactTestColumns()
		retrieveHardnessTestColumns()
	}, [
		isChemicalGroupExpanded,
		isTensileTestGroupExpanded,
		isHardnessTestGroupExpanded,
		isImpactTestGroupExpanded,
		plppState.selectedPlppColumns,
		plppState.hasClickOnViewTestsFromSelection,
	    plppState.isNeedToRefreshPlppColumns,
	])

	const addBackgroundHeaderColorAndBorder = (isGroupExpanded, start, end) => {
		const array = document.getElementsByTagName('tr')[1]
			? Array.from(
					document.getElementsByTagName('tr')[1] &&
						document.getElementsByTagName('tr')[1].getElementsByTagName('th')
			  )
			: []
		array.forEach((element, index) => {
			if (index >= start && index <= end) {
				element.style.background = isGroupExpanded ? '#F2FAFD' : '#FFFFFF'
				element.style.borderLeft = index === start ? 'solid 1px lightgrey' : 'none'
				element.style.borderRight = index === end ? 'solid 1px lightgrey' : 'none'
			}
		})
	}

	const setExpandHeader = (columns: string[], isGroupExpanded: boolean) => {
		setTimeout(() => {
			let startGroup = -1
			let endGroup = -1

			// CSS to stick header and filter
			const array = Array.from(document.getElementsByClassName('MuiTableRow-root'))
			array.forEach((lineElement, lineIndex) => {
				const isHeaderElement = lineElement.getElementsByTagName('th').length > 0
				const isFilterElement = isContainingHeaderWithExpand() ? lineIndex === 2 : lineIndex === 1
				const rowElements = isHeaderElement
					? lineElement.getElementsByTagName('th')
					: lineElement.getElementsByTagName('td')

				if (isHeaderElement || isFilterElement) {
					Array.from(rowElements).forEach((element, columnIndex) => {
						if (isHeaderElement || isFilterElement) {
							element.style.position = 'sticky'
							element.style.top =
								lineIndex === 0
									? '0px'
									: lineIndex === 1
									? (array[0] as HTMLElement).offsetHeight + 'px'
									: (array[0] as HTMLElement).offsetHeight + (array[1] as HTMLElement).offsetHeight + 'px'
							if (isHeaderElement && columnIndex === 1) {
								element.style.left = '0'
							}
							if (isFilterElement) {
								element.style.background = '#FFFFFF'
							}
							element.style.zIndex = columnIndex === 1 ? '999' : '998'
						}
						if (isHeaderElement) {
							if (element.textContent === t('plpp-data.' + columns[0] + '.name')) {
								startGroup = columnIndex
							}
							if (element.textContent === t('plpp-data.' + columns[columns.length - 1] + '.name')) {
								endGroup = columnIndex
							}
						}
					})
				}
			})

			if (startGroup !== -1 && endGroup !== -1)
				addBackgroundHeaderColorAndBorder(isGroupExpanded, startGroup, endGroup)
		}, 0)
	}

	useEffect(() => {
		// CSS to stick heat column : It's always the first column
		const array = Array.from(document.getElementsByClassName('MuiTableRow-root'))
		array.forEach((lineElement, lineIndex) => {
			const isHeaderElement = lineElement.getElementsByTagName('th').length > 0
			const isFilterElement = lineIndex === (isContainingHeaderWithExpand() ? 2 : 1)
			const rowElements = isHeaderElement
				? lineElement.getElementsByTagName('th')
				: lineElement.getElementsByTagName('td')

			const heatElement = rowElements[1]
			if (heatElement) {
				heatElement.style.position = 'sticky'
				heatElement.style.left = '0'
				heatElement.style.zIndex = isHeaderElement || isFilterElement ? '999' : '998'
                heatElement.style.backgroundColor = heatElement.parentElement.style.backgroundColor === "rgba(199, 225, 191, 0.5)" ? "rgb(227,240,223)" : "#FFFFFF";
                heatElement.style.transition = "all 300ms ease 0s"
            }
		})
	})

	useEffect(() => {
		// Put expand collapse header before column name header
		if (
			document.getElementsByTagName('thead') &&
			document.getElementsByTagName('thead')[0] &&
			document.getElementById('headerExpand')
		) {
			document.getElementsByTagName('thead')[0].prepend(document.getElementById('headerExpand'))
		}

		// Stick header and put right column and border for expands
		setExpandHeader(chemicalAnalysisColumns, isChemicalGroupExpanded)
		setExpandHeader(tensileTestColumns, isTensileTestGroupExpanded)
		setExpandHeader(impactTestColumns, isImpactTestGroupExpanded)
		setExpandHeader(hardnessTestColumns, isHardnessTestGroupExpanded)
	})

	const getColumnsToDisplay = () => {
		return plppState.plppColumns
			.filter(column => {
				return (
					(column.chemicalAnalysisGroup === undefined &&
						column.tensileTestGroup === undefined &&
						column.impactTestGroup === undefined &&
						column.hardnessTestGroup === undefined) ||
					(column.chemicalAnalysisGroup && chemicalAnalysisColumns.indexOf(column.field) > -1) ||
					(column.tensileTestGroup && tensileTestColumns.indexOf(column.field) > -1) ||
					(column.impactTestGroup && impactTestColumns.indexOf(column.field) > -1) ||
					(column.hardnessTestGroup && hardnessTestColumns.indexOf(column.field) > -1)
				)
			})
			.filter(column => column.visible && column.isForTestView)
	}

	const getDataToDisplay = () => {
		return plppState.displayedTestsPlppData
	}

	const setSelectedRows = (selectedPlppData: any[]) => {
		plppDispatch({ type: 'set_selected_tests_plpp_data', selectedTestsPlppData: selectedPlppData })
	}

	const getNumberOfColumnsBeforeGroups = () => {
		return (
			plppState.plppColumns.filter(column => {
				return (
					column.visible &&
					column.isForTestView &&
					column.chemicalAnalysisGroup === undefined &&
					column.tensileTestGroup === undefined &&
					column.hardnessTestGroup === undefined &&
					column.impactTestGroup === undefined
				)
			}).length + 1
		)
	}

	const isContainingHeaderWithExpand = () => {
		return (
			chemicalAnalysisColumns.length > 0 ||
			tensileTestColumns.length > 0 ||
			impactTestColumns.length > 0 ||
			hardnessTestColumns.length > 0
		)
	}

	const RenderHeaderWithExpand = () => {
		const { t } = useTranslation()
		return (
			<TableRow id="headerExpand">
				<th className={classes.noBackground}></th>
				<th className={classes.noBackground}></th>
				<th colSpan={getNumberOfColumnsBeforeGroups() - 2} className={classes.noBackground}></th>
				{chemicalAnalysisColumns.length > 0 && (
					<th
						colSpan={chemicalAnalysisColumns.length}
						className={[
							classes.chemicalAnalysisHeader,
							isChemicalGroupExpanded ? classes.backgroundExtended : classes.noBackground
						].join(' ')}
					>
						<span
							onClick={() => setIsChemicalGroupExpanded(false)}
							className={[classes.collapse_btn, isChemicalGroupExpanded ? classes.red : classes.grey].join(' ')}
						>
							<Icon fontSize={'large'} className={classes.vertical_align}>
								<img src={expandLeft} height={36} alt="Logo" />
							</Icon>
							<span className={classes.vertical_align}>{t('Collapse')}</span>
						</span>
						<span color="grey">
							<span>{t('Chemical Analysis') + ' [' + retrieveAllChemicalAnalysisColumns().length + '] '}</span>
						</span>
						<span
							onClick={() => setIsChemicalGroupExpanded(true)}
							className={[classes.expand_btn, isChemicalGroupExpanded ? classes.grey : classes.green].join(' ')}
						>
							<span className={classes.vertical_align}>{t('Extend')}</span>
							<Icon fontSize={'large'} className={classes.vertical_align}>
								<img src={expandRight} height={36} alt="Logo" />
							</Icon>
						</span>
					</th>
				)}
				{tensileTestColumns.length > 0 && (
					<th
						colSpan={tensileTestColumns.length}
						className={[
							classes.chemicalAnalysisHeader,
							isTensileTestGroupExpanded ? classes.backgroundExtended : classes.noBackground
						].join(' ')}
					>
						<span
							onClick={() => setIsTensileTestGroupExpanded(false)}
							className={[classes.collapse_btn, isTensileTestGroupExpanded ? classes.red : classes.grey].join(
								' '
							)}
						>
							<Icon fontSize={'large'} className={classes.vertical_align}>
								<img src={expandLeft} height={36} alt="Logo" />
							</Icon>
							<span className={classes.vertical_align}>{t('Collapse')}</span>
						</span>
						<span color="grey">
							<span>
								{t('plpp-data.manageDataColumns.columns.tensile_test') +
									' [' +
									retrieveAllTensileTestColumns().length +
									'] '}
							</span>
						</span>
						<span
							onClick={() => setIsTensileTestGroupExpanded(true)}
							className={[classes.expand_btn, isTensileTestGroupExpanded ? classes.grey : classes.green].join(
								' '
							)}
						>
							<span className={classes.vertical_align}>{t('Extend')}</span>
							<Icon fontSize={'large'} className={classes.vertical_align}>
								<img src={expandRight} height={36} alt="Logo" />
							</Icon>
						</span>
					</th>
				)}
				{impactTestColumns.length > 0 && (
					<th
						colSpan={impactTestColumns.length}
						className={[
							classes.chemicalAnalysisHeader,
							isImpactTestGroupExpanded ? classes.backgroundExtended : classes.noBackground
						].join(' ')}
					>
						<span
							onClick={() => setIsImpactTestGroupExpanded(false)}
							className={[classes.collapse_btn, isImpactTestGroupExpanded ? classes.red : classes.grey].join(
								' '
							)}
						>
							<Icon fontSize={'large'} className={classes.vertical_align}>
								<img src={expandLeft} height={36} alt="Logo" />
							</Icon>
							<span className={classes.vertical_align}>{t('Collapse')}</span>
						</span>
						<span color="grey">
							<span>
								{t('plpp-data.manageDataColumns.columns.impact_test') +
									' [' +
									retrieveAllImpactTestColumns().length +
									'] '}
							</span>
						</span>
						<span
							onClick={() => setIsImpactTestGroupExpanded(true)}
							className={[classes.expand_btn, isImpactTestGroupExpanded ? classes.grey : classes.green].join(
								' '
							)}
						>
							<span className={classes.vertical_align}>{t('Extend')}</span>
							<Icon fontSize={'large'} className={classes.vertical_align}>
								<img src={expandRight} height={36} alt="Logo" />
							</Icon>
						</span>
					</th>
				)}
				{hardnessTestColumns.length > 0 && (
					<th
						colSpan={hardnessTestColumns.length}
						className={[
							classes.chemicalAnalysisHeader,
							isHardnessTestGroupExpanded ? classes.backgroundExtended : classes.noBackground
						].join(' ')}
					>
						<span
							onClick={() => setIsHardnessTestGroupExpanded(false)}
							className={[classes.collapse_btn, isHardnessTestGroupExpanded ? classes.red : classes.grey].join(
								' '
							)}
						>
							<Icon fontSize={'large'} className={classes.vertical_align}>
								<img src={expandLeft} height={36} alt="Logo" />
							</Icon>
							<span className={classes.vertical_align}>{t('Collapse')}</span>
						</span>
						<span color="grey">
							<span>
								{t('plpp-data.manageDataColumns.columns.hardness_test') +
									' [' +
									retrieveAllHardnessTestColumns().length +
									'] '}
							</span>
						</span>
						<span
							onClick={() => setIsHardnessTestGroupExpanded(true)}
							className={[classes.expand_btn, isHardnessTestGroupExpanded ? classes.grey : classes.green].join(
								' '
							)}
						>
							<span className={classes.vertical_align}>{t('Extend')}</span>
							<Icon fontSize={'large'} className={classes.vertical_align}>
								<img src={expandRight} height={36} alt="Logo" />
							</Icon>
						</span>
					</th>
				)}
			</TableRow>
		)
	}

	return pageLoading ? (
		<LoadingOverlay open={true} />
	) : (
		<FilterableTable
			columns={getColumnsToDisplay()}
			data={getDataToDisplay()}
			currentPageNumber={plppState.pageNumber}
			setCurrentPageNumber={(pageNumber: number) => plppDispatch({ type: 'set_page_number', pageNumber })}
			autoResetFilters={false}
			title={<ChipsFilters isPipeView={false} filters={plppState.filteredTestsColumnsMap}/>}
			currentPageSize={plppState.pageSize}
			setCurrentPageSize={(pageSize: number) => plppDispatch({ type: 'set_page_size', pageSize })}
			components={{
				Header: propsHeader => (
					<div style={{ display: 'contents' }}>
						{isContainingHeaderWithExpand() && RenderHeaderWithExpand()}
						<MTableHeader {...propsHeader} />
					</div>
				)
			}}
			selectedData={plppState.selectedTestsPlppData}
			setSelectedRows={setSelectedRows}
		/>
	)
}

export default TestsPlppDataTable
