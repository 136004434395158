import { Button, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PlppContext } from '../../stores/PlppStore'

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      clearAllButton: {
         color: theme.palette.primary.main,
         boxShadow: '0px 2px 3px #00000029',
         height: 'fit-content',
         marginRight: '15px',
         padding: '5px'
      },
      button: {
         margin: '0px 24px',
      },
      chips: {
        padding: '5px 10px',
        border: 'black solid 1px',
        borderRadius: '20px',
        marginRight: '20px',
        fontSize: 'small',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
      },
      clearIcon: {
        padding: '0',
        marginLeft:'5px'
      },
      cancelIcon:{
        color:'black'
      },
      chipsFilterComponent:{
        display:'flex',
        width:'100%',
        alignItems: 'center'
      },
      filterText: {
        fontWeight:'bold',
        marginRight:'20px'
      }

   })
);

const ChipsFilters = props => {
   const classes = useStyles();
   const { plppDispatch } = useContext(PlppContext);

   const { t } = useTranslation();

	const handleClear = (key) => {
		onFilterChange(key)
	}
	const handleClearAll = () => {
	props.filters.forEach((value, key) => {
			onFilterChange(key)
	    })
	}
    const onFilterChange = (key: string) => {
          plppDispatch({
            type: 'remove_filter_from_columns_map',
            filterToRemoveFromPipeView: props.isPipeView ? key : undefined,
            filterToRemoveFromTestView: props.isPipeView ? undefined : key
          });
    };
    const elems = []


    props.filters.forEach((value,key) => {
        const hasFilter = value.min.length !== 0 ||
                          value.max.length !== 0 ||
                          value.values.length !== 0 ||
                          (value !== undefined &&
                             value !== undefined &&
                                (value.values.length !== 0 ||
                                value.min.length !== 0 ||
                                value.max.length !== 0 ))
        if(hasFilter){
        elems.push(<p key={key} className={classes.chips} id={t('plpp-data.' + key + '.name') + "-chips"}>
                                                {t('plpp-data.' + key + '.name')}
                                                    <IconButton aria-label='close' id={t('plpp-data.' + key + '.name') + "-clear"} className={classes.clearIcon} onClick={() => handleClear(key)}>
                                                        <CancelIcon className={classes.cancelIcon} />
                                                    </IconButton>

                                            </p>)
        }
    })
    const isNotEmptyOrUndefined = () => {
        let isNotEmptyOfUndefined = false;
        props.filters.forEach((value, key) => {
            const hasFilter = value.min.length !== 0 ||
                              value.max.length !== 0 ||
                              value.values.length !== 0 ||
                              (value !== undefined &&
                                 value !== undefined &&
                                    (value.values.length !== 0 ||
                                    value.min.length !== 0 ||
                                    value.max.length !== 0 ))
            if(hasFilter) isNotEmptyOfUndefined = true
        });

        return isNotEmptyOfUndefined
    }

    return (
          <div className={classes.chipsFilterComponent}>
            {isNotEmptyOrUndefined() && <p className={classes.filterText}>Filters </p>}
            {elems}
            {isNotEmptyOrUndefined() &&
                     <Button
                        id='clearAllButton'
                        className={classes.clearAllButton}
                        onClick={handleClearAll}
                     >
                        {t('ClearAll')}
                     </Button>}
          </div>

    )

};


export default ChipsFilters;


