import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

export type ConfirmationDialogProps = {
  button: any;
  title: string;
  description: string;
  positiveButtonText: string;
  negativeButtonText: string;
  positiveButtonCallback?: any;
  negativeButtonCallback?: any;
  mainDivStyle?: any;
};

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={props.mainDivStyle}>
      <div onClick={() => setOpen(true)}>{props.button}</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={
              props.negativeButtonCallback
                ? props.negativeButtonCallback
                : handleClose
            }
            color='primary'
          >
            {props.negativeButtonText}
          </Button>
          <Button
            onClick={
              props.positiveButtonCallback
                ? props.positiveButtonCallback
                : handleClose
            }
            color='primary'
            autoFocus
          >
            {props.positiveButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
