import React from 'react';
import {makeStyles, Theme, Grid} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // padding: theme.spacing(2),
  }
}));

const PageBody = (props) => {
  const classes = useStyles();
  
  return (
    <Grid container spacing={0} className={classes.root} {...props}>
      <Grid item xs={12}>
        {props.children}        
      </Grid>
    </Grid>
  );
};

export default React.memo(PageBody);