import { Button, Grid, IconButton } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import requests from '../../../api/api';
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';
import ScrollableListWithSearch from '../../../components/common/ScrollableListWithSearch';
import { PlppContext } from '../../../stores/PlppStore';
import { UserContext } from '../../../stores/UserStore';
import ColumnsOrdering from './ColumnsOrdering';
import TemplateManagement from './TemplateManagement';

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      openModalButton: {
         color: theme.palette.primary.main,
         boxShadow: '0px 2px 3px #00000029',
      },
      paper: {
         backgroundColor: theme.palette.background.paper,
         top: '1%',
         left: '1%',
         right: '20%',
         maxHeight: '98vh',
         position: 'relative',
         width: '98vw',
         height: '98vh',
         padding: theme.spacing(2, 4, 3),
      },
      button: {
         margin: '0px 24px',
      },
      applyButton: {
         width: '30%',
         margin: 'auto',
         color: 'white',
      },
      grid: {
         height: '400',
         maxHeight: '400'
      },
   })
);

const DisplayModalButton = (props: { isPipeView: boolean }) => {
   const classes = useStyles();
   const { currentUser } = useContext(UserContext);
   const { plppState, plppDispatch } = useContext(PlppContext);
   const [open, setOpen] = React.useState(false);
   const [previousSelectedColumns, setPreviousSelectedColumns] = React.useState(
      plppState.selectedPlppColumns
   );
   const [previousSelectedTemplateId, setPreviousSelectedTemplateId] = React.useState(
      plppState.selectedTemplateId
   );

   const [levelTwoColumns, setLevelTwoColumns] = React.useState([])
   const [levelThreeColumns, setLevelThreeColumns] = React.useState([])

   const [selectedLabelForLevelOne, setSelectedLabelForLevelOne] = React.useState("")
   const [selectedLabelForLevelTwo, setSelectedLabelForLevelTwo] = React.useState("")
   const [diff, setDiff] = React.useState([])


   const { t } = useTranslation();

   const allColumnKeys = props.isPipeView
      ? ColumnsOrdering.sections.filter((section) => section.isForPipeView).flatMap((section) => {
         return section.keys
            .filter((key) => key.keys || key.isForPipeView)
            .flatMap((key) => {
               if (key.keys && key.keys.length > 0) {
                  return key.keys
               } else {
                  return key
               }
            })
            .map((key) => key.label)
      })
      : ColumnsOrdering.sections.filter((section) => section.isForTestView).flatMap((section) => {
         return section.keys
            .filter((key) => key.keys || key.isForTestView)
            .flatMap((key) => {
               if (key.keys && key.keys.length > 0) {
                  return key.keys
               } else {
                  return key
               }
            })
            .map((key) => key.label)
      });

   const handleModalOpen = () => {
      setOpen(true);
      setLevelTwoColumns([])
      setLevelThreeColumns([])
   };

   const handleModalClose = () => {
      // Close the modal
      setOpen(false);
      // Reset the list of selected columns to currently visible columns
      const selectedColumns = plppState.plppColumns
         .filter((column) => column.visible)
         .map((column) => column.key);
      setPreviousSelectedColumns(selectedColumns);
      plppDispatch({
         type: 'set_selected_plpp_columns',
         selectedColumns,
      });
      // Reset the previous template id
      plppDispatch({
         type: 'set_selected_template_id',
         selectedTemplateId: previousSelectedTemplateId,
      });
   };

   const onClickOnSaveButton = () => {
      plppDispatch({
         type: 'update_plpp_columns_visibility',
      });
      // Close the modal
      setOpen(false);
      // Set the list of selected columns to currently visible columns
      const selectedColumns = plppState.plppColumns
         .filter((column) => column.visible)
         .map((column) => column.key);
      setPreviousSelectedColumns(selectedColumns);
      plppDispatch({
         type: 'set_selected_plpp_columns',
         selectedColumns,
      });
      // Set the previous template id
      setPreviousSelectedTemplateId(plppState.selectedTemplateId);
   };

   const handleSelectAll = (level?: number) => {

      let keysToAdd = props.isPipeView ? allColumnKeys : allColumnKeys.filter(key => key && key.startsWith('carbonSteelHeat'));
      if (level === 2) {
         // retrieve all key to add for level 2
         keysToAdd = retrieveAllKeys(levelTwoColumns, allColumnKeys);

      } else if (level === 3) {
         keysToAdd = retrieveAllKeys(levelThreeColumns, allColumnKeys);
      }

      plppDispatch({
         type: 'add_selected_plpp_columns',
         columnsToAdd: keysToAdd,
      });

      onExpandLevelOne("root", selectedLabelForLevelOne, false)
      onExpandLevelTwo(selectedLabelForLevelOne, selectedLabelForLevelTwo)
   };

   const handleClearAll = (level?: number) => {
      let keysToRemove = props.isPipeView
         ? allColumnKeys.filter((key) => !getMandatoryKeysOfView().includes(key))
         : allColumnKeys.filter(key => key && key.startsWith('carbonSteelHeat') && !getMandatoryKeysOfView().includes(key));
      if (level === 2) {
         // retrieve all key to add for level 2
         keysToRemove = retrieveAllKeys(levelTwoColumns, allColumnKeys).filter((key) => !getMandatoryKeysOfView().includes(key))
      } else if (level === 3) {
         keysToRemove = retrieveAllKeys(levelThreeColumns, allColumnKeys);
      }

      plppDispatch({
         type: 'remove_selected_plpp_columns',
         columnsToRemove: keysToRemove,
      });

      onExpandLevelOne("root", selectedLabelForLevelOne, false)
      onExpandLevelTwo(selectedLabelForLevelOne, selectedLabelForLevelTwo)
   };

   const isModified = () => {
      const currentSelectedColumns = plppState.selectedPlppColumns;
      return plppState.selectedTemplateId !== previousSelectedTemplateId
         || (
            !currentSelectedColumns.every((item) =>
               previousSelectedColumns.includes(item)
            ) ||
            !previousSelectedColumns.every((item) =>
               currentSelectedColumns.includes(item)
            )
         );
   };


   const onExpandLevelOne = (parent: string, current: string, clearLastLevel = true) => {
      if (current !== "") {
         const values = ColumnsOrdering.sections.filter((section) => { return section.label === current })[0].keys
            .filter((key) => props.isPipeView ? key.keys || key.isForPipeView : key.keys || key.isForTestView)
            .map((key) => {
               return {
                  label: t('plpp-data.' + key.label + '.name'),
                  name: key.label,
                  parent: current,
                  hasChild: key.keys && key.keys.length > 0,
                  checked: isSecondLevelChecked(current, key.label),
                  disabled: props.isPipeView ? key.isMandatoryPipeView : key.isMandatoryTestView
               }
            });
         setSelectedLabelForLevelOne(current)
         setSelectedLabelForLevelTwo("")
         setLevelTwoColumns(values)
         if (clearLastLevel) {
            setLevelThreeColumns([])
         }
      }
   }

   const onExpandLevelTwo = (parent: string, current: string) => {

      if (parent !== "" && current !== "") {
         const currentKeys = ColumnsOrdering.sections.filter((section) => section.label === parent)[0].keys
            .filter((child) => child.label === current)[0].keys;
         if (currentKeys && currentKeys.length > 0) {
            const values = currentKeys.map((key) => {
               return {
                  label: t('plpp-data.' + key.label + '.name'),
                  name: key.label,
                  parent: parent + "." + current,
                  hasChild: key.keys && key.keys.length > 0,
                  checked: plppState.selectedPlppColumns.includes(key.label),
                  disabled: props.isPipeView ? key.isMandatoryPipeView : key.isMandatoryTestView
               }
            });
            setSelectedLabelForLevelTwo(current)
            setLevelThreeColumns(values)
         }

      }
   }

   const handleSelection = (check: boolean, keys: string[]) => {
      if (check) {
         plppDispatch({
            type: 'add_selected_plpp_columns',
            columnsToAdd: keys,
         });
      } else {
         plppDispatch({
            type: 'remove_selected_plpp_columns',
            columnsToRemove: keys.filter((key) => !getMandatoryKeysOfView().includes(key)),
         });
      }
   }

   const onCheckUpdate = (parent: string, label: string, check: boolean) => {
      if (parent === "root") {
         // first level
         const allSubKeysFromSelectedSection = ColumnsOrdering.sections.filter((section) => section.label === label)[0].keys
            .flatMap((key) => {
               if (key.keys && key.keys.length > 0) {
                  return key.keys
               } else {
                  return key
               }
            })
            .map((key) => key.label)
         handleSelection(check, allSubKeysFromSelectedSection)
         onExpandLevelOne("root", label)

      } else if (ColumnsOrdering.sections.filter((section) => section.label === parent).length > 0) {
         // second level
         const allSubKeysFromSelectedSection = ColumnsOrdering.sections.filter((section) => section.label === parent)[0].keys
            .filter((key) => key.label === label).flatMap((key) => {
               if (key.keys && key.keys.length > 0) {
                  return key.keys
               } else {
                  return key
               }
            })
            .map((key) => key.label)

         handleSelection(check, allSubKeysFromSelectedSection)
         onExpandLevelTwo(parent, label)

      } else {
         // third level
         const parentLevelTwo = parent.substring(parent.indexOf(".") + 1)

         handleSelection(check, [label])
         onExpandLevelOne("root", selectedLabelForLevelOne, false)
         setSelectedLabelForLevelTwo(parentLevelTwo)
      }
   }

   const isSecondLevelChecked = (parent: string, label: string) => {
      const children = ColumnsOrdering.sections.filter((section) => section.label === parent)[0].keys.filter((child) => child.label === label)[0]
      if (children && children.keys && children.keys.length > 0) {
         for (let littleChild of children.keys) {
            if (plppState.selectedPlppColumns.includes(littleChild.label)) {
               return true;
            }
         }
      } else {
         if (plppState.selectedPlppColumns.includes(label)) {
            return true;
         }
      }
      return false;
   }

   const getNumberOfElementChecked = (label: string) => {
      let numberOfSelectedElement = 0;
      const children = ColumnsOrdering.sections.filter((section) => section.label === label)[0].keys;
      for (let child of children) {
         if (child.keys && child.keys.length > 0) {
            for (let littleChild of child.keys) {
               if (plppState.selectedPlppColumns.includes(littleChild.label)) {
                  numberOfSelectedElement++;
               }
            }
         } else {
            if (plppState.selectedPlppColumns.includes(child.label)) {
                  numberOfSelectedElement++;
            }
         }
      }
      return numberOfSelectedElement;
   }

   const refreshView = () => {
      setLevelTwoColumns([])
      setLevelThreeColumns([])
   };

   useEffect(() => {
      if (!previousSelectedTemplateId) {
         requests.getAllTemplatesByUserData(currentUser.id).then((templates) => {

            let defaultTemplate = templates.find(template => template.name === 'Default template');

            if (defaultTemplate) {
               setPreviousSelectedColumns(defaultTemplate.carbonSteelColumns.map(column => column.label));
            }
         });
      }
   }, [setPreviousSelectedColumns, previousSelectedTemplateId, currentUser]);

   const getMandatoryKeysOfView = () => {
      const keys = ColumnsOrdering.sections.flatMap((section) => {
         return section.keys.filter((key) => props.isPipeView ? key.isMandatoryPipeView : key.isMandatoryTestView)
            .flatMap((key) => {
               if (key.keys && key.keys.length > 0) {
                  return key.keys;
               } else {
                  return key;
               }
            })
            .map((key) => {
               return key.label;
            });
      });
      return keys
   }

   useEffect(() => {
      const keys = getMandatoryKeysOfView();
      if (!props.isPipeView) setDiff(keys.filter(x => !plppState.selectedPlppColumns.includes(x)));
      !props.isPipeView ? plppDispatch({ type: 'add_selected_plpp_columns', columnsToAdd: keys.filter(x => !plppState.selectedPlppColumns.includes(x)) }) : plppDispatch({ type: 'remove_selected_plpp_columns', columnsToRemove: diff })
   }, [props.isPipeView])

   return (
      <div>
         <Button
            id='manageColumnsButton'
            className={classes.openModalButton}
            endIcon={<SettingsIcon />}
            onClick={handleModalOpen}
         >
            {t('plpp-data.manageDataColumns.title')}
         </Button>

         <Modal open={open} onClose={handleModalClose}>
            <div className={classes.paper}>
               <div>
                  {isModified() ? (
                     <ConfirmationDialog
                        button={
                           <IconButton aria-label='close'>
                              <CancelIcon color='primary' />
                           </IconButton>
                        }
                        title={t('plpp-data.confirmationDialog.title')}
                        description={t('plpp-data.confirmationDialog.description')}
                        positiveButtonText={t('plpp-data.yes')}
                        negativeButtonText={t('plpp-data.no')}
                        positiveButtonCallback={handleModalClose}
                        negativeButtonCallback={undefined}
                        mainDivStyle={{ float: 'right' }}
                     />
                  ) : (
                     <div onClick={() => setOpen(false)} style={{ float: 'right' }}>
                        <IconButton aria-label='close'>
                           <CancelIcon color='primary' />
                        </IconButton>
                     </div>
                  )}

                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                     <h2 id='simple-modal-title'>
                        {t('plpp-data.manageDataColumns.title')}
                     </h2>
                     <TemplateManagement refreshView={refreshView} setPreviousSelectedColumns={setPreviousSelectedColumns} />
                  </div>

                  <div>
                     <p
                        id='simple-modal-description'
                        style={{ display: 'inline-block' }}
                     >
                        {t('plpp-data.manageDataColumns.description')}
                     </p>
                  </div>


                  <Grid container spacing={3} wrap='nowrap'>

                     {/* 1st section */}
                     <Grid item xs={12} justify="center" >
                        <ScrollableListWithSearch
                           options={
                              ColumnsOrdering.sections.map((section) => {
                                 return {
                                    label: `plpp-data.manageDataColumns.columns.${section.label}`,
                                    name: section.label,
                                    parent: "root",
                                    hasChild: true,
                                    checked: getNumberOfElementChecked(section.label) > 0,
                                    selectedChildCount: getNumberOfElementChecked(section.label),
                                    disabled: (props.isPipeView && !section.isForPipeView) || (!props.isPipeView && !section.isForTestView),
                                 }
                              })
                           }
                           onExpand={onExpandLevelOne}
                           onCheck={onCheckUpdate}
                           handleClearAll={() => handleClearAll(1)}
                           handleSelectAll={() => handleSelectAll(1)}
                           sectionNumber={0}
                        />
                     </Grid>

                     {/* 2nd section */}
                     <Grid item xs={12} justify="center" >
                        <ScrollableListWithSearch
                           options={levelTwoColumns}
                           onExpand={onExpandLevelTwo}
                           onCheck={onCheckUpdate}
                           handleClearAll={() => handleClearAll(2)}
                           handleSelectAll={() => handleSelectAll(2)}
                           sectionNumber={1}
                        />
                     </Grid>

                     {/* 3rd section */}
                     <Grid item xs={12} justify="center" >
                        <ScrollableListWithSearch
                           options={levelThreeColumns}
                           onExpand={() => { }}
                           onCheck={onCheckUpdate}
                           handleClearAll={() => handleClearAll(3)}
                           handleSelectAll={() => handleSelectAll(3)}
                           sectionNumber={2}
                        />
                     </Grid>
                  </Grid>
               </div>

               <div style={{ textAlign: 'center', position: 'absolute', bottom: 0, justifyContent: 'center', width: '95vw', alignItems: 'center', marginBottom: '40px' }}>

                  <Button
                     color='primary'
                     className={classes.button}
                     onClick={() => handleClearAll()}
                  >
                     Clear all
                  </Button>
                  <Button
                     color='primary'
                     variant='contained'
                     className={classes.applyButton}
                     onClick={onClickOnSaveButton}
                  >
                     {t('plpp-data.apply')}
                  </Button>
               </div>
            </div>
         </Modal>
      </div>
   );

};


export default DisplayModalButton;


function retrieveAllKeys(columns: any[], allColumnKeys: any[]): string[] {
   const displayedLabel = columns.flatMap((col) => col.name);
   const keys = ColumnsOrdering.sections.flatMap((section) => {
      return section.keys.filter((key) => displayedLabel.includes(key.label))
         .flatMap((key) => {
            if (key.keys && key.keys.length > 0) {
               return key.keys;
            } else {
               return key;
            }
         })
         .map((key) => key.label);
   });
   return keys.length > 0 ? keys : displayedLabel;
}

