import React, { forwardRef } from 'react';

import MaterialTable, {MTableToolbar} from 'material-table';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import FilterAltIcon from '@material-ui/icons/FilterAlt';

const DataTable = (props) => {
    return (
        <MaterialTable
            icons={{
                Search: forwardRef((props, ref) => <SearchIcon color="primary" {...props} ref={ref} />), 
                Export: forwardRef((props, ref) => <DownloadIcon color="primary" {...props} ref={ref} />), 
                ResetSearch: forwardRef((props, ref) => <ClearIcon color="primary" {...props} ref={ref} />),
                Filter: forwardRef((props, ref) => <FilterAltIcon color="primary" {...props} ref={ref} fontSize="small" />),
            }}
            options={{
                showTitle: props.title ? true : false,
                padding: 'dense',
                filtering: props.filtering,
                selection: props.selection,                
                pageSize: props.pageSize ? props.pageSize : window.innerHeight > 930 ? 20 : window.innerHeight > 722 ? 15 : 10,
                pageSizeOptions: [5, 10, 15, 20, 50],
                paginationType: 'stepped',
                exportAllData: true,
                exportButton: true,
                minBodyHeight: props.minBodyHeight ? props.minBodyHeight : '68vh',
                exportDelimiter: ';',
                exportFileName: 'data-table-export_'+Date.now(),
                emptyRowsWhenPaging: false,
                showTextRowsSelected: false,
                actionsColumnIndex: props.actionsColumnIndex ? props.actionsColumnIndex : 0,
                headerStyle: {
                    color: 'rgb(121 121 121)',
                    marginTop: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    whiteSpace: 'nowrap'
                },                            
                rowStyle: {
                    color: 'rgb(121 121 121)'
                },
                filterCellStyle: {
                    margin: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    fontSize: '13px'
                },
                filterRowStyle: {
                    margin: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                },
                actionsCellStyle: {
                    // backgroundColor: 'red',
                    // borderRadius: 'unset'
                }
            }}
            components={{
                Toolbar: props => (
                  <div style={{maxHeight: '60px' }}>
                    <MTableToolbar {...props} />
                  </div>
                )
            }}            
            {...props}
        />        
    );
}

export default React.memo(DataTable);