import React from 'react';
import { makeStyles, Theme, Container } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
     padding: theme.spacing(2)
  }
}));

const PageContainer = (props) => {
  const classes = useStyles();
  
  return (
    <Container className={classes.root} maxWidth="xl" {...props}>
        {props.children}        
    </Container>
  );
};

export default React.memo(PageContainer);