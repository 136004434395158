import { css } from 'styled-components'

export interface IWithTheme {
    theme: any;
}

// withBackgroundColor
export interface IWithBackgroundColor {
    backgroundColor: string;
}

export const withBackgroundColor = (defaultValue: string = 'inherit') => (props: Partial<IWithBackgroundColor & IWithTheme>) => {
    let {theme}: {theme?: IWithTheme['theme']} = props;
    let {backgroundColor = defaultValue}: {backgroundColor?: IWithBackgroundColor['backgroundColor']} = props;
    return css`background-color: ${theme.colors? theme.colors[backgroundColor]: backgroundColor};`
}

// withBackgroundGradient
export interface IWithBackgroundGradient {
    backgroundGradient: string;
}

export const withBackgroundGradient = (defaultValue: string = 'inherit') => (props: Partial<IWithBackgroundGradient & IWithTheme>) => {
    let {theme}: {theme?: IWithTheme['theme']} = props;
    let {backgroundGradient = defaultValue}: {backgroundGradient?: IWithBackgroundGradient['backgroundGradient']} = props;
    return (backgroundGradient !== defaultValue) && css`background: ${theme.gradients && backgroundGradient && theme.gradients[backgroundGradient]? theme.gradients[backgroundGradient]: backgroundGradient};`
}

// withBorderRadius
export interface IWithBorderRadius {
    borderRadius: string;
}

export const withBorderRadius = (defaultValue: string = '0px') => (props: Partial<IWithBorderRadius & IWithTheme>) => {
    let {theme}: {theme?: IWithTheme['theme']} = props;
    let {borderRadius = defaultValue}: {borderRadius?: IWithBorderRadius['borderRadius']} = props;
    return borderRadius && css`border-radius: ${theme.borderRadius && borderRadius && theme.borderRadius[borderRadius]? theme.borderRadius[borderRadius]: borderRadius};`
}

// withColor
export interface IWithColor {
    color: string;
}

export const withColor = (defaultValue: string = 'inherit') => (props: Partial<IWithColor & IWithTheme>) => {
    let {theme}: {theme?: IWithTheme['theme']} = props;
    let {color = defaultValue}: {color?: IWithColor['color']} = props;
    return css`color: ${theme.colors? theme.colors[color]: color};`
};

// withElevation
export interface IWithElevation {
    elevation: string;
}

export const withElevation = (defaultValue: string = 'none') => (props: Partial<IWithElevation & IWithTheme>) => {
    let {theme}: {theme?: IWithTheme['theme']} = props;
    let {elevation = defaultValue}: {elevation?: IWithElevation['elevation']} = props;
    return css`box-shadow: ${theme.boxShadows && elevation && theme.boxShadows[elevation]? theme.boxShadows[elevation]: elevation};`
};

// withFlex
export interface IWithFlex {
    display: string;
    flex: string;
    flexWrap: string;
    flexDirection: string;
    alignItems: string;
    alignSelf: string;
    justifyContent: string;
}

export const withFlex =  (defaultValue: any = {}) => (props: Partial<IWithFlex>) => {
    let {
        flex,
        flexWrap = defaultValue.flexWrap || 'nowrap',
        flexDirection = defaultValue.flexDirection || 'column',
        alignItems = defaultValue.alignItems || 'flex-start',
        alignSelf = defaultValue.alignSelf || 'auto',
        justifyContent = defaultValue.justifyContent || 'flex-start'
    }: {
        flex?: IWithFlex['flex'],
        flexWrap?: IWithFlex['flexWrap'],
        flexDirection?: IWithFlex['flexDirection'],
        alignItems?: IWithFlex['alignItems'],
        alignSelf?: IWithFlex['alignSelf'],
        justifyContent?: IWithFlex['justifyContent']
    } = props;

    return css`
      display: flex;
      ${flex && css`flex: ${flex};`}
      flex-wrap: ${flexWrap};
      flex-direction: ${flexDirection};
      align-items: ${alignItems};
      align-self: ${alignSelf};
      justify-content: ${justifyContent};`
};

// withFontSize
export interface IWithFontSize {
    fontSize: string;
}

export const withFontSize = (defaultValue: string = 'medium') => (props: Partial<IWithFontSize & IWithTheme>) => {
    let {theme}: {theme?: IWithTheme['theme']} = props;
    let {fontSize = defaultValue}: {fontSize?: IWithFontSize['fontSize']} = props;
    return fontSize && css`font-size: ${theme.fontSizes && fontSize && theme.fontSizes[fontSize]? theme.fontSizes[fontSize]: fontSize};`
};

// withFontWeight
export interface IWithFontWeight {
    fontWeight: string;
}

export const withFontWeight = (defaultValue: string = 'normal') => (props: Partial<IWithFontWeight>) => {
    if(!props) {
        props = {};
    }
    let {fontWeight = defaultValue}: {fontWeight?: IWithFontWeight['fontWeight']} = props;
    return css`font-weight: ${fontWeight};`
};

// withHeight
export interface IWithHeight {
    height: string;
}

export const withHeight = (defaultValue: string = 'auto') => (props: Partial<IWithHeight>) => {
    let {height = defaultValue}: {height?: IWithHeight['height']} = props;
    return css`height: ${height};`
};

// withMargin
export interface IWithMargin {
    margin: string;
}

export const withMargin = (defaultValue: string = '0px') => (props: Partial<IWithMargin>) => {
    let {margin = '0px'}: {margin?: IWithMargin['margin']} = props;
    return css`margin: ${margin};`
};

// withPadding
export interface IWithPadding {
    padding: string;
}

export const withPadding = (defaultValue: string = '0px') => (props: Partial<IWithPadding>) => {
    let {padding = defaultValue}: {padding?: IWithPadding['padding']} = props;
    return css`padding: ${padding};`
};

// withPosition
export interface IWithPosition {
    position: string;
}

export const withPosition = (defaultValue: string = 'static') => (props: Partial<IWithPosition>) => {
    let {position = defaultValue}: {position?: IWithPosition['position']} = props;
    return css`position: ${position};`
};

// withWidth
export interface IWithWidth {
    width: string;
}

export const withWidth = (defaultValue: string = 'auto') => (props: Partial<IWithWidth>) => {
    let {width = defaultValue}: {width?: IWithWidth['width']} = props;
    return css`width: ${width};`
};
