/** @format */

import {
	Box,
	Button,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Popover,
	Select,
	TextField
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import React, { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import requests from '../../../api/api'
import ConfirmationDialog from '../../../components/common/ConfirmationDialog'
import LoadingOverlay from '../../../components/common/LoadingOverlay'
import { PlppContext } from '../../../stores/PlppStore'
import { UserContext } from '../../../stores/UserStore'
import ColumnsOrdering from './ColumnsOrdering'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			color: theme.palette.primary.main,
			margin: '0px 12px'
		},
		formControl: {
			margin: '0px 12px',
			minWidth: 150
		}
	})
)

const TemplateManagement = props => {
	const classes = useStyles()
	const { t } = useTranslation()

	const { currentUser, setAuthState } = useContext(UserContext)
	const { plppState, plppDispatch } = useContext(PlppContext)

	const [anchorElNewTemplateNameTextfield, setAnchorElNewTemplateNameTextfield] =
		React.useState<HTMLButtonElement | null>(null)
	const isOpenNewTemplateNameTextfield = Boolean(anchorElNewTemplateNameTextfield)
	const [isOpenSelectTemplate, setOpenSelectTemplate] = React.useState<boolean>(false)

	const [modalPageLoading, setModalPageLoading] = React.useState(false)
	const [newTemplateName, setNewTemplateName] = React.useState('')

	const saveTemplate = useCallback(
		(methodToSave, templateToSave) => {
			setModalPageLoading(true)
			return methodToSave(templateToSave)
				.then(newTemplate => {
					return requests.getAllTemplatesByUserData(currentUser.id).then(data => {
						plppDispatch({
							type: 'set_all_templates',
							allTemplates: data
						})
						plppDispatch({
							type: 'set_selected_template_id',
							selectedTemplateId: newTemplate.id
						})
						plppDispatch({
							type: 'set_selected_plpp_columns',
							selectedColumns: newTemplate.carbonSteelColumns.map(column => column.label)
						})
					})
				})
				.finally(() => setModalPageLoading(false))
				.catch(e => {
					if (e.resultCode === 'error.expired.token') {
						setAuthState('LOGIN')
					} else {
						toast.error(e.message)
					}
				})
		},
		[plppDispatch, setAuthState, currentUser]
	)

	useEffect(() => {
		const selectedTemplate = plppState.allTemplates.find(template => template.id === plppState.selectedTemplateId)
		if (selectedTemplate !== undefined) {
			updateSelectedTemplate(selectedTemplate)
		}
	}, [plppState.selectedTemplateId])

	const saveDefaultTemplate = useCallback(() => {
		const defaultVisibleColumns = ColumnsOrdering.sections.flatMap(section => {
			return section.keys
				.flatMap(key => {
					if (key.keys && key.keys.length > 0) {
						return key.keys
					} else {
						return key
					}
				})
				.filter(key => key.checkedByDefault === true)
		})

		const defaultTemplate = {
			name: 'Default template',
			user: { cognitoId: currentUser.id },
			carbonSteelColumns: defaultVisibleColumns
		}
		return saveTemplate(requests.createTemplate, defaultTemplate)
	}, [saveTemplate, currentUser])

	useEffect(() => {
		if (currentUser) {
			if (!plppState.selectedTemplateId) {
				setModalPageLoading(true)
				requests
					.getAllTemplatesByUserData(currentUser.id)
					.then(async templates => {
						let defaultTemplate = templates.find(template => template.name === 'Default template')

						if (defaultTemplate) {
							plppDispatch({
								type: 'set_all_templates',
								allTemplates: templates
							})
							plppDispatch({
								type: 'set_selected_template_id',
								selectedTemplateId: defaultTemplate.id
							})
							plppDispatch({
								type: 'set_selected_plpp_columns',
								selectedColumns: defaultTemplate.carbonSteelColumns.map(column => column.label)
							})
						} else {
							// Save default template if not existing
							await saveDefaultTemplate()
						}
					})
					.finally(() => setModalPageLoading(false))
					.catch(e => {
						if (e.resultCode === 'error.expired.token') {
							setAuthState('LOGIN')
						} else {
							toast.error(e.message)
						}
					})
			}
		} else {
			setAuthState('LOGIN')
		}
	}, [plppDispatch, saveDefaultTemplate, setAuthState, currentUser])

	const handleOpenPopoverSave = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElNewTemplateNameTextfield(event.currentTarget)
	}

	const handleClosePopupSave = () => {
		setAnchorElNewTemplateNameTextfield(null)
		setNewTemplateName('')
	}

	const handleChangeNewTemplateName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewTemplateName(event.target.value)
	}

	const isTemplateAlreadyExisting = () => {
		return plppState.allTemplates.find(template => template.name === newTemplateName) !== undefined
	}

	const handleCreateTemplate = () => {
		handleClosePopupSave()

		const templateToSave = {
			name: newTemplateName,
			user: { cognitoId: currentUser.id },
			carbonSteelColumns: plppState.selectedPlppColumns.map(column => ({ label: column }))
		}
		return saveTemplate(requests.createTemplate, templateToSave)
	}

	const handleUpdateTemplate = () => {
		handleClosePopupSave()

		const templateToSave = {
			...plppState.allTemplates.find(template => template.name === newTemplateName),
			carbonSteelColumns: plppState.selectedPlppColumns.map(column => ({ label: column }))
		}
		return saveTemplate(requests.updateTemplate, templateToSave)
	}

	const onOpenSelectTemplate = () => {
		setOpenSelectTemplate(true)
	}

	const onCloseSelectTemplate = () => {
		setOpenSelectTemplate(false)
	}

	const handleSelectTemplate = (event: React.ChangeEvent<{ value: string }>) => {
		const selectedTemplate = plppState.allTemplates.find(template => template.id === Number(event.target.value))
		updateSelectedTemplate(selectedTemplate)
		setOpenSelectTemplate(false)
	}

	const updateSelectedTemplate = selectedTemplate => {
		plppDispatch({
			type: 'set_selected_template_id',
			selectedTemplateId: selectedTemplate.id
		})
		plppDispatch({
			type: 'set_selected_plpp_columns',
			selectedColumns: selectedTemplate.carbonSteelColumns.map(column => column.label)
		})
		props.refreshView()
	}

	const handleDeleteTemplate = (templateId: string) => {
		setModalPageLoading(true)
		requests
			.deleteTemplate(templateId)
			.then(() => {
				const currentSelectedTemplateId = plppState.selectedTemplateId
				const templates = plppState.allTemplates.filter(template => template.id !== templateId)
				plppDispatch({
					type: 'set_all_templates',
					allTemplates: templates
				})

				const selectedTemplate = templates.find(template =>
					plppState.selectedTemplateId === templateId
						? template.name === 'Default template'
						: template.id === currentSelectedTemplateId
				)
				updateSelectedTemplate(selectedTemplate)
			})
			.finally(() => setModalPageLoading(false))
			.catch(e => {
				if (e.resultCode === 'error.expired.token') {
					setAuthState('LOGIN')
				} else {
					toast.error(e.message)
				}
			})
	}

	return (
		<div>
			<LoadingOverlay open={modalPageLoading} />
			<Grid container alignItems="flex-end">
				<Button aria-describedby="simple-popover" className={classes.button} onClick={handleOpenPopoverSave}>
					{t('plpp-data.manageDataColumns.saveAsTemplate')}
				</Button>

				<Popover
					id="simple-popover"
					open={isOpenNewTemplateNameTextfield}
					anchorEl={anchorElNewTemplateNameTextfield}
					onClose={handleClosePopupSave}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					<div>
						<Grid container alignItems="center" style={{ padding: '20px' }}>
							<TextField
								id="newTemplateName"
								label={t('plpp-data.manageDataColumns.myNewTemplateName')}
								value={newTemplateName}
								onChange={handleChangeNewTemplateName}
								style={{ marginBottom: '10px', width: '150' }}
							/>

							{isTemplateAlreadyExisting() ? (
								<ConfirmationDialog
									button={
										<Button className={classes.button} style={{ marginRight: '10px' }}>
											{t('SAVE')}
										</Button>
									}
									title={''}
									description={t('plpp-data.confirmationDialogSaveTemplate.description')}
									positiveButtonText={t('plpp-data.overwrite')}
									negativeButtonText={t('plpp-data.cancel')}
									positiveButtonCallback={handleUpdateTemplate}
									negativeButtonCallback={undefined}
									mainDivStyle={{ float: 'right' }}
								/>
							) : (
								<Button
									className={classes.button}
									style={{ marginRight: '10px' }}
									onClick={handleCreateTemplate}
								>
									{t('SAVE')}
								</Button>
							)}
						</Grid>
					</div>
				</Popover>

				<FormControl className={classes.formControl}>
					<InputLabel id="template-select-label">{t('plpp-data.manageDataColumns.selectTemplate')}</InputLabel>
					<Select
						labelId="template-select-label"
						value={plppState.selectedTemplateId}
						name="template"
						onChange={handleSelectTemplate}
						open={isOpenSelectTemplate}
						onOpen={onOpenSelectTemplate}
						onClose={onCloseSelectTemplate}
						renderValue={() => {
							return plppState.allTemplates.find(template => template.id === plppState.selectedTemplateId)
								? plppState.allTemplates.find(template => template.id === plppState.selectedTemplateId).name
								: ''
						}}
					>
						{plppState.allTemplates.map(template => (
							<MenuItem value={template.id}>
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center'
									}}
								>
									{template.name}
									<Box
										visibility={template.name === 'Default template' ? 'hidden' : 'visible'}
										onClick={() => handleDeleteTemplate(template.id)}
									>
										<IconButton>
											<DeleteIcon />
										</IconButton>
									</Box>
								</div>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
		</div>
	)
}

export default TemplateManagement
