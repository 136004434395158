import React, { useReducer, Dispatch } from 'react';

type ActualState = {
  plants: any[]; 
  clients: any[]; 
  octgActualUploadFields: any; 
  octgActualUploadFiles: any[]; 
  octgActualDetails: any[]; 
  octgActualColumns: any[]; 
  octgActualData: any[];
  octgActualLoad: any;
  octgUploadedCertificates: any[];
}

type ActualAction =
  | { type: 'set_plants'; plants: any[] }
  | { type: 'set_clients'; clients: any[] }
  | { type: 'set_octg_actual_upload_fields'; octgActualUploadFields: any }
  | { type: 'set_octg_actual_upload_files'; octgActualUploadFiles: any[] }
  | { type: 'set_octg_actual_details'; octgActualDetails: any[] }
  | { type: 'set_octg_actual_columns'; octgActualColumns: any[] }
  | { type: 'set_octg_actual_data'; octgActualData: any[] }
  | { type: 'set_octg_actual_load'; octgActualLoad: any }
  | { type: 'set_uploaded_certificates'; octgUploadedCertificates: any[] }

const initialActualState: ActualState = {
  plants: [{id: 0, name: 'Loading...'}],
  clients: [{id: 0, name: 'Loading...'}],
  octgActualUploadFields: {loadTitle: undefined, plantId: 0, clientId: 0, weightUnit: false, lengthUnit: false},
  octgActualUploadFiles: [],
  octgActualDetails: [],
  octgActualColumns: [],
  octgActualData: [],
  octgActualLoad: {},
  octgUploadedCertificates: []
};

const actualReducer = (state: ActualState, action: ActualAction): ActualState => {
  switch (action.type) {
    case 'set_plants':
      return {
        ...state,
        plants: action.plants,
      }
    case 'set_clients':
      return {
        ...state,
        clients: action.clients,
      }
    case 'set_octg_actual_upload_fields':
      return {
        ...state,
        octgActualUploadFields: action.octgActualUploadFields,
      }
    case 'set_octg_actual_upload_files':
      return {
        ...state,
        octgActualUploadFiles: action.octgActualUploadFiles,
      }
    case 'set_octg_actual_details':
      return {
        ...state,
        octgActualDetails: action.octgActualDetails,
      }
    case 'set_octg_actual_columns':
      return {
        ...state,
        octgActualColumns: action.octgActualColumns,
      }
    case 'set_octg_actual_data':
      return {
        ...state,
        octgActualData: action.octgActualData,
      }
    case 'set_octg_actual_load':
      return {
        ...state,
        octgActualLoad: action.octgActualLoad,
      }
    case 'set_uploaded_certificates':
      return {
        ...state,
        octgUploadedCertificates: action.octgUploadedCertificates,
      }
    }
}

type NominalState = {
  plants: any[]; 
  clients: any[]; 
  octgNominalUploadFields: any; 
  octgNominalUploadFiles: any[]; 
  octgNominalDetails: any[]; 
  octgNominalColumns: any[]; 
  octgNominalData: any[];
  octgNominalLoad: any;
}

type NominalAction =
  | { type: 'set_plants'; plants: any[] }
  | { type: 'set_clients'; clients: any[] }
  | { type: 'set_octg_nominal_upload_fields'; octgNominalUploadFields: any }
  | { type: 'set_octg_nominal_upload_files'; octgNominalUploadFiles: any[] }
  | { type: 'set_octg_nominal_details'; octgNominalDetails: any[] }
  | { type: 'set_octg_nominal_columns'; octgNominalColumns: any[] }
  | { type: 'set_octg_nominal_data'; octgNominalData: any[] }
  | { type: 'set_octg_nominal_load'; octgNominalLoad: any }

const initialNominalState: NominalState = {
  plants: [{id: 0, name: 'Loading...'}],
  clients: [{id: 0, name: 'Loading...'}],
  octgNominalUploadFields: {loadTitle: undefined, plantId: 0, clientId: 0, weightUnit: false, lengthUnit: false},
  octgNominalUploadFiles: [],
  octgNominalDetails: [],
  octgNominalColumns: [],
  octgNominalData: [],
  octgNominalLoad: {},
};

const nominalReducer = (state: NominalState, action: NominalAction): NominalState => {
  switch (action.type) {
    case 'set_plants':
      return {
        ...state,
        plants: action.plants,
      }
    case 'set_clients':
      return {
        ...state,
        clients: action.clients,
      }
    case 'set_octg_nominal_upload_fields':
      return {
        ...state,
        octgNominalUploadFields: action.octgNominalUploadFields,
      }
    case 'set_octg_nominal_upload_files':
      return {
        ...state,
        octgNominalUploadFiles: action.octgNominalUploadFiles,
      }
    case 'set_octg_nominal_details':
      return {
        ...state,
        octgNominalDetails: action.octgNominalDetails,
      }
    case 'set_octg_nominal_columns':
      return {
        ...state,
        octgNominalColumns: action.octgNominalColumns,
      }
    case 'set_octg_nominal_data':
      return {
        ...state,
        octgNominalData: action.octgNominalData,
      }
    case 'set_octg_nominal_load':
      return {
        ...state,
        octgNominalLoad: action.octgNominalLoad,
      }
    }
}

export const OctgContext = React.createContext<{
  octgActualState: ActualState;
  octgActualDispatch: Dispatch<ActualAction>;
  octgNominalState: NominalState;
  octgNominalDispatch: Dispatch<NominalAction>;
}>({
  octgActualState: initialActualState,
  octgActualDispatch: () => null,
  octgNominalState: initialNominalState,
  octgNominalDispatch: () => null
});


export const OctgProvider = (props: any) => {
  const [octgActualState, octgActualDispatch] = useReducer(actualReducer, initialActualState);
  const [octgNominalState, octgNominalDispatch] = useReducer(nominalReducer, initialNominalState);

  return <OctgContext.Provider value={{ octgActualState, octgActualDispatch, octgNominalState, octgNominalDispatch }}>
    {props.children}
  </OctgContext.Provider>;
}